"use strict";

import Vue from "vue";
import axios from "axios";
import { domainObject } from "@/constant.js";
// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = "Bearer " + store.state.Profile4User[configs.JWTTokenName];
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: "https://peap.datacom.com.tw/", //process.env.NODE_ENV === "production" ? "/RIS_WF1" : "/",
  // baseURL: domainObject.platform,
  // baseURL: process.env.NODE_ENV === "production" ? "/api/" : "/",
 
  baseURL: "/api/",
  
  //baseURL: "http://localhost:50430/",
  //baseURL: "http://th-pp004.24drs.com/",
  
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 300 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  // function(config) {
  //   config.headers.Authorization =
  //     "Bearer " + `${window.sessionStorage.token}`.replace(/\"/g, "");
  //   return config;
  // },
  function(config) {
    // Do something before request is sent
    //config.headers.Authorization = "Bearer " + store.state.Profile4User[configs.JWTTokenName];
    config.headers.Authorization =
      "Bearer " + `${window.sessionStorage.token}`.replace(/\"/g, "");
    config.headers.common.Accept = "application/json";
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response.data;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
