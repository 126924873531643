<template>
  <b-navbar
    class="d-print-none mb-2"
    toggleable="lg"
    style="position:sticky; top:0;z-index:99;"
    variant="primary"
  >
    <b-navbar-brand hidden href="#" style="color:var(--light)"
      >DTC</b-navbar-brand
    >
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav v-if="dtcDebug">
        <b-nav-item to="totalSpend" hidden class="student"
          >0.公費生經費總表</b-nav-item
        >
        <b-nav-item
          to="doc5"
          id="20103"
          class="student"
          :class="defaultLink ? 'active' : ''"
          >待遇補助查詢</b-nav-item
        >
        <b-nav-item to="doc1" id="20103" class="student"
          >待遇補助請領清冊</b-nav-item
        >
        <b-nav-item to="doc2" hidden class="student">2.補助收支</b-nav-item>
        <b-nav-item to="doc3" hidden class="student">3.醫事人員養成</b-nav-item>
        <b-nav-item to="doc4" id="20100" class="student"
          >待遇補助申請表</b-nav-item
        >
        <b-nav-item to="doc6" id="20105" class="student"
          >教學設備補助</b-nav-item
        >
        <b-nav-item to="apply1" hidden class="student"
          >7.年資核計及申請書</b-nav-item
        >
        <b-nav-item
          to="doc8-4"
          class="nurse"
          :class="defaultLink ? 'active' : ''"
          >護理補助申請表</b-nav-item
        >
        <b-nav-item to="doc8-1" id="20201" class="nurse"
          >各校護理查詢</b-nav-item
        >
        <b-nav-item to="doc8-3" class="nurse">各校護理申請表</b-nav-item>
        <b-nav-item to="doc8-2" id="20202" class="nurse"
          >護理補助預估概算</b-nav-item
        >
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto">
        <div class="dtx-red">
          <span>溫馨提醒: * 為可編輯欄位</span>
        </div>
        <b-button
          variant="warning"
          @click="$router.push('home')"
          v-if="dtcDebug"
          class="mr-3"
        >
          <i class="fas fa-undo-alt mr-1"></i>返回
        </b-button>
        <b-button
          variant="danger"
          :disabled="stopPrint"
          @click="printCurrentPage"
        >
          <i class="fas fa-print mr-1" />列印
        </b-button>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto" hidden>
        <b-nav-form>
          <b-form-input
            size="sm"
            class="mr-sm-2"
            placeholder="Search"
          ></b-form-input>
          <b-button size="sm" class="my-2 my-sm-0" type="submit"
            >Search</b-button
          >
        </b-nav-form>

        <b-nav-item-dropdown text="Lang" right hidden>
          <b-dropdown-item href="#">EN</b-dropdown-item>
          <b-dropdown-item href="#">ES</b-dropdown-item>
          <b-dropdown-item href="#">RU</b-dropdown-item>
          <b-dropdown-item href="#">FA</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown right hidden>
          <!-- Using 'button-content' slot -->
          <template v-slot:button-content>
            <em>User</em>
          </template>
          <b-dropdown-item href="#">Profile</b-dropdown-item>
          <b-dropdown-item href="#">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import html2canvas from "html2canvas";
export default {
  name: "tdd",
  data() {
    return {
      stopPrint: false,
      defaultLink: true,
      dtcDebug,
    };
  },

  methods: {
    printCurrentPage() {
      const num = localStorage.dtcPage4Print;
      const msg = "dtc-print-self-" + num;
      document.documentElement.scrollTop = 0;
      const el = document.querySelector("#dtc-print-it");
      const print = document.querySelectorAll(".dtc-print-btn2");
      const appEl = document.querySelector("#app");
      appEl.classList.remove("dtc-edit-mode");
      appEl.classList.add("dtc-print-mode");
      if (print) {
        print.forEach((el) => (el.style.display = "none"));
      }
      const removes = document.querySelectorAll(".dtc-remove");
      if (removes) {
        removes.forEach((el) => (el.style.display = "none"));
      }
      if (!el) {
        window.print();
      } else {
        let scale = 1;
        if (this.$route.path.includes("doc1")) {
          scale = 0.9;
        } else if (
          this.$route.path.includes("doc8-1") ||
          this.$route.path.includes("doc8-3")
        ) {
          scale = 0.8;
        }
        html2canvas(el, { backgroundColor: "#FFF", scale }).then((canvas) => {
          const src = canvas.toDataURL();
          this.printImage(src);
        });
      }
    },
    printImage(src) {
      const appEl = document.querySelector("#app");
      appEl.classList.add("dtc-edit-mode");
      appEl.classList.remove("dtc-print-mode");
      const print = document.querySelectorAll(".dtc-print-btn2");
      if (print) {
        print.forEach((el) => (el.style.display = "inline-block"));
      }
      const removes = document.querySelectorAll(".dtc-remove");
      if (removes) {
        removes.forEach((el) => (el.style.display = "block"));
      }
      var win = window.open("about:blank", "_new");
      win.document.open();
      win.document.write(
        [
          "<html>",
          "   <head>",
          "   </head>",
          "   <style>@page { size: A4 landscape }</style>",
          '   <body onload="window.print()" onafterprint="window.close()">',
          '       <img src="' + src + '"/>',
          "   </body>",
          "</html>",
        ].join("")
      );
      win.document.close();
      this.stopPrint = false;
    },
  },
  mounted() {
    this.$root.$on("dtc-enable-print", () => (this.stopPrint = false));
    // const menu = JSON.parse(localStorage.dtxBuget).find(
    //   s => s.MenuId == "20000"
    // );

    // const subMenu = !this.$route.path.includes("doc8")
    //   ? menu.SubMenu[0]
    //   : menu.SubMenu[1];
    // if (!this.$route.path.includes("doc8")) {
    //   [...document.querySelectorAll(".nurse")].forEach((el) => {
    //     el.style.display = "none";
    //   });
    // } else {
    //   [...document.querySelectorAll(".student")].forEach((el) => {
    //     el.style.display = "none";
    //   });
    // }
  },
  watch: {
    $route() {
      document.documentElement.scrollTop = 0;
      this.defaultLink = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navbar-light .navbar-nav .nav-link {
  color: var(--light);
}
</style>
