// export const domainObject = {
//   recruit: "https://ioscee.mohw.gov.tw",
//   map: "https://iosgis.mohw.gov.tw",
//   platform: "https://iosback.mohw.gov.tw",
// };
// export const domainObject = {
//   recruit: "https://applyvue.datacom.com.tw/",
//   map: "https://gisvue.datacom.com.tw/",
//   platform: "https://peap.datacom.com.tw/",
//   platformOriginal: "https://vue.datacom.com.tw/api/",
// };
export const domainObject = {
  //recruit: "https://ioscee.datacom.com.tw/",
  //map: "https://iosgos.datacom.com.tw/",
 
  //platform: "https://ios.datacom.com.tw/",
  //platformOriginal: "https://iosback.datacom.com.tw/api/",
  
  //platform: "http://th-pp006.24drs.com/",
  //platformOriginal: "http://th-pp004.24drs.com/", //api
  
  platform: "https://192.168.5.137/",
  platformOriginal: "https://srv12-web01.24drs.com/API/",

  //platform: "https://ioscee.mohw.gov.tw/",
  //platformOriginal: "https://ioscee.mohw.gov.tw/api/",

  //platform: "https://iosback.mohw.gov.tw/",
  //platformOriginal: "https://iosback.mohw.gov.tw/api/",
};

export const isActiveObject = [
  {
    value: null,
    text: "全部",
  },
  {
    value: true,
    text: "啟用",
  },
  {
    value: false,
    text: "不啟用",
  },
];

export const healthKindObject = [
  {
    value: null,
    text: "全部",
  },
  {
    value: "01",
    text: "衛生局",
  },
  {
    value: "02",
    text: "醫院",
  },
  {
    value: "03",
    text: "衛生所",
  },
];

export const kindOfType = {
  type : {1:'在地養成',  2:'偏護菁英',  3:'重點科別培育'},
  option : [
    {
      value: 1,
      text: "在地養成",
    },
    {
      value: 2,
      text: "偏護菁英",
    },
    {
      value: 3,
      text: "重點科別培育",
    },
  ] 
};

export const countryObject = [
  {
    value: 1,
    text: "原住民",
  },
  {
    value: 2,
    text: "澎湖縣",
  },
  {
    value: 3,
    text: "金門縣",
  },
  {
    value: 4,
    text: "連江縣",
  },
  {
    value: 5,
    text: "琉球籍",
  },
  {
    value: 6,
    text: "綠島籍",
  },
  {
    value: 7,
    text: "蘭嶼籍",
  },
  {
    value: 8,
    text: "偏 鄉",
  },
  {
    value: 9,
    text: "不分籍屬",
  },
];

export const genderObject = [
  { value: null, text: "全部" },
  {
    value: "M",
    text: "男",
  },
  {
    value: "F",
    text: "女",
  },
];

export const serviceSysOption = [
  //"衛福部",
  //"退輔會",
  "衛福部經費",
  "退輔會經費",
];
export const serviceSystemOption = [
  "衛福部",
  "退輔會",
];

export const majorObject = [
  "醫師",
  "牙醫",
  "藥學",
  "護理",
  "醫檢",
  "放射",
  "物治",
  "醫技",
  "職治",
  "語治",
  "呼吸",
  "臨心",
];

export const countriesObject = [
  { text: "全部", value: null },
  "基隆市",
  "嘉義市",
  "台北市",
  "嘉義縣",
  "新北市",
  "台南市",
  "桃園市",
  "高雄市",
  "新竹市",
  "屏東縣",
  "新竹縣",
  "台東縣",
  "苗栗縣",
  "花蓮縣",
  "台中市",
  "宜蘭縣",
  "彰化縣",
  "澎湖縣",
  "南投縣",
  "金門縣",
  "雲林縣",
  "連江縣",
];

export const localStatusObject = [
  { text: "全部", value: null },
    "在學期間",// "在學中",
    "畢業",    // "畢業",
    //"醫師證書",// "追辦列管",
    "訓練期間",// "訓練中",
    "服務期間",// "服務中",
    "服務期滿",// "服務期滿",
    "展緩期間",// "展緩期間",
    "履約終止",// "履約終止",
    //"醫事人員證書",// "醫事人員證書",
];

export const GradeStatusObject = [
  { text: "ALL", value: null },
    "一",// "在學中",
    "二",    // "畢業",
    "三",// "追辦列管",
    "四",// "訓練中",
    "五",// "服務中",
    "六",// "服務期滿",
    "七",
];

export const DepartmentStatusObject = [
  { text: "未選擇", value: "" },
    "內科",
    "外科",    
    "婦產科",
    "兒科",
    "急診科",
    "麻醉科",
    "神經外科",
];

export const statusRuralObject = [
  { text: "全部", value: null },
  // // "未入學",
    "在學期間",// "在學中",
    "畢業",    // "畢業",
    "醫師證書",// "追辦列管",
    //"訓練期間",// "訓練中",
    "服務期間",// "服務中",
    "服務期滿",// "服務期滿",
    "展緩期間",// "展緩期間",
    "履約終止",// "履約終止",
];
// export const allServiceObject = {
//   option1: ["未入學"],
//   option2: [{ text: "全部", value: null }, "在學中", "休學", "延畢"],//old:在學中
//   option3: ["畢業"],//old:畢業
//   option4: [{ text: "全部", value: null }, "PGY訓練","專科訓練","訓練中","進修中",  ],//old:訓練中
//   option5: [{ text: "全部", value: null }, "服務中", "進修中"],//old:服務中 
//   option6: [{ text: "全部", value: null }, "留任", "不留任", "退休", "死亡"],//old:服務期滿
//   option7: [{ text: "全部", value: null }, "未考照", "尚未服務", "考照未訓"],//old:追辦列管 醫師證書
//   option8: [{ text: "全部", value: null }, "退學", "還款中", "因病", "死亡"],//old:履約終止
// };
//搜尋用
export const allServiceObject = {
  '未入學':   ["未入學"],
  '在學期間': [{ text: "全部", value: null }, "在學中", "休學", "延畢"],//old:在學中 new:在學期間 
  '畢業':     ["考照中", "考取未訓"],//old:畢業 new:畢業
  '訓練期間': [{ text: "全部", value: null }, "一般醫學訓練(PGY)","專科訓練","次專科訓練","訓練中","進修中",  ],//old:訓練中 new:訓練期間 
  '服務期間': [{ text: "全部", value: null }, "服務中","進修中","國際援助"],//old:服務中 new:服務期間 
  '服務期滿': [{ text: "全部", value: null }, "留任", "不留任", "退休", "死亡","已領證","未領證"],//old:服務期滿 new:服務期滿 
  '醫師證書': [{ text: "全部", value: null }, "未考照", "考取中", "尚未服務", "考照未訓"],//old:追辦列管 new:醫師證書
  '履約終止': [{ text: "全部", value: null }, "退學","還款中","因病","重大傷病","死亡","其他"],//old:履約終止 new:履約終止 
  '展緩期間': [{ text: "全部", value: null }, "服兵役","重大傷病","懷孕或育嬰","公費留學","其他"],//new:展緩期間 
};

//個人編輯用
export const allRuralServiceObject = {
  '未入學':   [{ text: "未入學", value: null }],
  '在學期間': ["在學中", "休學", "延畢"],//old:在學中 new:在學期間 
  '畢業':     ["考照中", "考取未訓"],//old:畢業 new:畢業
  '訓練期間': ["一般醫學訓練(PGY)","專科訓練","次專科訓練","訓練中","進修中",  ],//old:訓練中 new:訓練期間 
  '服務期間': ["服務中","進修中","國際援助"],//old:服務中 new:服務期間 
  '服務期滿': ["留任", "不留任", "退休", "死亡","已領證","未領證"],//old:服務期滿 new:服務期滿 
  '醫師證書': ["未考照", "考取中", "尚未服務", "考照未訓"],//old:追辦列管 new:醫師證書
  '履約終止': ["退學","還款中","因病","重大傷病","死亡","其他"],//old:履約終止 new:履約終止 
  '展緩期間': ["服兵役","重大傷病","懷孕或育嬰","公費留學","其他"],//new:展緩期間 
};

const year = new Date().getFullYear() - 1920;
export const yearsObject = new Array(50).fill().map((s, i) => year + 20 - i);

export const hometownObject = [
  { text: "臺北市中正區", value: 100 },
  { text: "臺北市大同區", value: 103 },
  { text: "臺北市中山區", value: 104 },
  { text: "臺北市松山區", value: 105 },
  { text: "臺北市大安區", value: 106 },
  { text: "臺北市萬華區", value: 108 },
  { text: "臺北市信義區", value: 110 },
  { text: "臺北市士林區", value: 111 },
  { text: "臺北市北投區", value: 112 },
  { text: "臺北市內湖區", value: 114 },
  { text: "臺北市南港區", value: 115 },
  { text: "臺北市文山區", value: 116 },
  { text: "基隆市仁愛區", value: 200 },
  { text: "基隆市信義區", value: 201 },
  { text: "基隆市中正區", value: 202 },
  { text: "基隆市中山區", value: 203 },
  { text: "基隆市安樂區", value: 204 },
  { text: "基隆市暖暖區", value: 205 },
  { text: "基隆市七堵區", value: 206 },
  { text: "新北市萬里區", value: 207 },
  { text: "新北市金山區", value: 208 },
  { text: "新北市板橋區", value: 220 },
  { text: "新北市汐止區", value: 221 },
  { text: "新北市深坑區", value: 222 },
  { text: "新北市石碇區", value: 223 },
  { text: "新北市瑞芳區", value: 224 },
  { text: "新北市平溪區", value: 226 },
  { text: "新北市雙溪區", value: 227 },
  { text: "新北市貢寮區", value: 228 },
  { text: "新北市新店區", value: 231 },
  { text: "新北市坪林區", value: 232 },
  { text: "新北市烏來區", value: 233 },
  { text: "新北市永和區", value: 234 },
  { text: "新北市中和區", value: 235 },
  { text: "新北市土城區", value: 236 },
  { text: "新北市三峽區", value: 237 },
  { text: "新北市樹林區", value: 238 },
  { text: "新北市鶯歌區", value: 239 },
  { text: "新北市三重區", value: 241 },
  { text: "新北市新莊區", value: 242 },
  { text: "新北市泰山區", value: 243 },
  { text: "新北市林口區", value: 244 },
  { text: "新北市蘆洲區", value: 247 },
  { text: "新北市五股區", value: 248 },
  { text: "新北市八里區", value: 249 },
  { text: "新北市淡水區", value: 251 },
  { text: "新北市三芝區", value: 252 },
  { text: "新北市石門區", value: 253 },
  { text: "宜蘭縣宜蘭市", value: 260 },
  { text: "宜蘭縣頭城鎮", value: 261 },
  { text: "宜蘭縣礁溪鄉", value: 262 },
  { text: "宜蘭縣壯圍鄉", value: 263 },
  { text: "宜蘭縣員山鄉", value: 264 },
  { text: "宜蘭縣羅東鎮", value: 265 },
  { text: "宜蘭縣三星鄉", value: 266 },
  { text: "宜蘭縣大同鄉", value: 267 },
  { text: "宜蘭縣五結鄉", value: 268 },
  { text: "宜蘭縣冬山鄉", value: 269 },
  { text: "宜蘭縣蘇澳鎮", value: 270 },
  { text: "宜蘭縣南澳鄉", value: 272 },
  { text: "連江縣南竿鄉", value: 209 },
  { text: "連江縣北竿鄉", value: 210 },
  { text: "連江縣莒光鄉", value: 211 },
  { text: "連江縣東引鄉", value: 212 },
  { text: "釣魚臺釣魚臺", value: 290 },
  { text: "南海島東沙群島", value: 817 },
  { text: "南海島南沙群島", value: 819 },
  { text: "新竹市東　區", value: 300 },
  { text: "新竹市北　區", value: 300 },
  { text: "新竹市香山區", value: 300 },
  { text: "新竹縣竹北市", value: 302 },
  { text: "新竹縣湖口鄉", value: 303 },
  { text: "新竹縣新豐鄉", value: 304 },
  { text: "新竹縣新埔鎮", value: 305 },
  { text: "新竹縣關西鎮", value: 306 },
  { text: "新竹縣芎林鄉", value: 307 },
  { text: "新竹縣寶山鄉", value: 308 },
  { text: "新竹縣竹東鎮", value: 310 },
  { text: "新竹縣五峰鄉", value: 311 },
  { text: "新竹縣橫山鄉", value: 312 },
  { text: "新竹縣尖石鄉", value: 313 },
  { text: "新竹縣北埔鄉", value: 314 },
  { text: "新竹縣峨眉鄉", value: 315 },
  { text: "桃園市中壢區", value: 320 },
  { text: "桃園市平鎮區", value: 324 },
  { text: "桃園市龍潭區", value: 325 },
  { text: "桃園市楊梅區", value: 326 },
  { text: "桃園市新屋區", value: 327 },
  { text: "桃園市觀音區", value: 328 },
  { text: "桃園市桃園區", value: 330 },
  { text: "桃園市龜山區", value: 333 },
  { text: "桃園市八德區", value: 334 },
  { text: "桃園市大溪區", value: 335 },
  { text: "桃園市復興區", value: 336 },
  { text: "桃園市大園區", value: 337 },
  { text: "桃園市蘆竹區", value: 338 },
  { text: "苗栗縣竹南鎮", value: 350 },
  { text: "苗栗縣頭份市", value: 351 },
  { text: "苗栗縣三灣鄉", value: 352 },
  { text: "苗栗縣南庄鄉", value: 353 },
  { text: "苗栗縣獅潭鄉", value: 354 },
  { text: "苗栗縣後龍鎮", value: 356 },
  { text: "苗栗縣通霄鎮", value: 357 },
  { text: "苗栗縣苑裡鎮", value: 358 },
  { text: "苗栗縣苗栗市", value: 360 },
  { text: "苗栗縣造橋鄉", value: 361 },
  { text: "苗栗縣頭屋鄉", value: 362 },
  { text: "苗栗縣公館鄉", value: 363 },
  { text: "苗栗縣大湖鄉", value: 364 },
  { text: "苗栗縣泰安鄉", value: 365 },
  { text: "苗栗縣銅鑼鄉", value: 366 },
  { text: "苗栗縣三義鄉", value: 367 },
  { text: "苗栗縣西湖鄉", value: 368 },
  { text: "苗栗縣卓蘭鎮", value: 369 },
  { text: "臺中市中　區", value: 400 },
  { text: "臺中市東　區", value: 401 },
  { text: "臺中市南　區", value: 402 },
  { text: "臺中市西　區", value: 403 },
  { text: "臺中市北　區", value: 404 },
  { text: "臺中市北屯區", value: 406 },
  { text: "臺中市西屯區", value: 407 },
  { text: "臺中市南屯區", value: 408 },
  { text: "臺中市太平區", value: 411 },
  { text: "臺中市大里區", value: 412 },
  { text: "臺中市霧峰區", value: 413 },
  { text: "臺中市烏日區", value: 414 },
  { text: "臺中市豐原區", value: 420 },
  { text: "臺中市后里區", value: 421 },
  { text: "臺中市石岡區", value: 422 },
  { text: "臺中市東勢區", value: 423 },
  { text: "臺中市和平區", value: 424 },
  { text: "臺中市新社區", value: 426 },
  { text: "臺中市潭子區", value: 427 },
  { text: "臺中市大雅區", value: 428 },
  { text: "臺中市神岡區", value: 429 },
  { text: "臺中市大肚區", value: 432 },
  { text: "臺中市沙鹿區", value: 433 },
  { text: "臺中市龍井區", value: 434 },
  { text: "臺中市梧棲區", value: 435 },
  { text: "臺中市清水區", value: 436 },
  { text: "臺中市大甲區", value: 437 },
  { text: "臺中市外埔區", value: 438 },
  { text: "臺中市大安區", value: 439 },
  { text: "彰化縣彰化市", value: 500 },
  { text: "彰化縣芬園鄉", value: 502 },
  { text: "彰化縣花壇鄉", value: 503 },
  { text: "彰化縣秀水鄉", value: 504 },
  { text: "彰化縣鹿港鎮", value: 505 },
  { text: "彰化縣福興鄉", value: 506 },
  { text: "彰化縣線西鄉", value: 507 },
  { text: "彰化縣和美鎮", value: 508 },
  { text: "彰化縣伸港鄉", value: 509 },
  { text: "彰化縣員林鎮", value: 510 },
  { text: "彰化縣社頭鄉", value: 511 },
  { text: "彰化縣永靖鄉", value: 512 },
  { text: "彰化縣埔心鄉", value: 513 },
  { text: "彰化縣溪湖鎮", value: 514 },
  { text: "彰化縣大村鄉", value: 515 },
  { text: "彰化縣埔鹽鄉", value: 516 },
  { text: "彰化縣田中鎮", value: 520 },
  { text: "彰化縣北斗鎮", value: 521 },
  { text: "彰化縣田尾鄉", value: 522 },
  { text: "彰化縣埤頭鄉", value: 523 },
  { text: "彰化縣溪州鄉", value: 524 },
  { text: "彰化縣竹塘鄉", value: 525 },
  { text: "彰化縣二林鎮", value: 526 },
  { text: "彰化縣大城鄉", value: 527 },
  { text: "彰化縣芳苑鄉", value: 528 },
  { text: "彰化縣二水鄉", value: 530 },
  { text: "南投縣南投市", value: 540 },
  { text: "南投縣中寮鄉", value: 541 },
  { text: "南投縣草屯鎮", value: 542 },
  { text: "南投縣國姓鄉", value: 544 },
  { text: "南投縣埔里鎮", value: 545 },
  { text: "南投縣仁愛鄉", value: 546 },
  { text: "南投縣名間鄉", value: 551 },
  { text: "南投縣集集鎮", value: 552 },
  { text: "南投縣水里鄉", value: 553 },
  { text: "南投縣魚池鄉", value: 555 },
  { text: "南投縣信義鄉", value: 556 },
  { text: "南投縣竹山鎮", value: 557 },
  { text: "南投縣鹿谷鄉", value: 558 },
  { text: "嘉義市東　區", value: 600 },
  { text: "嘉義市西　區", value: 600 },
  { text: "嘉義縣番路鄉", value: 602 },
  { text: "嘉義縣梅山鄉", value: 603 },
  { text: "嘉義縣竹崎鄉", value: 604 },
  { text: "嘉義縣阿里山", value: 605 },
  { text: "嘉義縣中埔鄉", value: 606 },
  { text: "嘉義縣大埔鄉", value: 607 },
  { text: "嘉義縣水上鄉", value: 608 },
  { text: "嘉義縣鹿草鄉", value: 611 },
  { text: "嘉義縣太保市", value: 612 },
  { text: "嘉義縣朴子市", value: 613 },
  { text: "嘉義縣東石鄉", value: 614 },
  { text: "嘉義縣六腳鄉", value: 615 },
  { text: "嘉義縣新港鄉", value: 616 },
  { text: "嘉義縣民雄鄉", value: 621 },
  { text: "嘉義縣大林鎮", value: 622 },
  { text: "嘉義縣溪口鄉", value: 623 },
  { text: "嘉義縣義竹鄉", value: 624 },
  { text: "嘉義縣布袋鎮", value: 625 },
  { text: "雲林縣斗南鎮", value: 630 },
  { text: "雲林縣大埤鄉", value: 631 },
  { text: "雲林縣虎尾鎮", value: 632 },
  { text: "雲林縣土庫鎮", value: 633 },
  { text: "雲林縣褒忠鄉", value: 634 },
  { text: "雲林縣東勢鄉", value: 635 },
  { text: "雲林縣臺西鄉", value: 636 },
  { text: "雲林縣崙背鄉", value: 637 },
  { text: "雲林縣麥寮鄉", value: 638 },
  { text: "雲林縣斗六市", value: 640 },
  { text: "雲林縣林內鄉", value: 643 },
  { text: "雲林縣古坑鄉", value: 646 },
  { text: "雲林縣莿桐鄉", value: 647 },
  { text: "雲林縣西螺鎮", value: 648 },
  { text: "雲林縣二崙鄉", value: 649 },
  { text: "雲林縣北港鎮", value: 651 },
  { text: "雲林縣水林鄉", value: 652 },
  { text: "雲林縣口湖鄉", value: 653 },
  { text: "雲林縣四湖鄉", value: 654 },
  { text: "雲林縣元長鄉", value: 655 },
  { text: "臺南市中西區", value: 700 },
  { text: "臺南市東　區", value: 701 },
  { text: "臺南市南　區", value: 702 },
  { text: "臺南市北　區", value: 704 },
  { text: "臺南市安平區", value: 708 },
  { text: "臺南市安南區", value: 709 },
  { text: "臺南市永康區", value: 710 },
  { text: "臺南市歸仁區", value: 711 },
  { text: "臺南市新化區", value: 712 },
  { text: "臺南市左鎮區", value: 713 },
  { text: "臺南市玉井區", value: 714 },
  { text: "臺南市楠西區", value: 715 },
  { text: "臺南市南化區", value: 716 },
  { text: "臺南市仁德區", value: 717 },
  { text: "臺南市關廟區", value: 718 },
  { text: "臺南市龍崎區", value: 719 },
  { text: "臺南市官田區", value: 720 },
  { text: "臺南市麻豆區", value: 721 },
  { text: "臺南市佳里區", value: 722 },
  { text: "臺南市西港區", value: 723 },
  { text: "臺南市七股區", value: 724 },
  { text: "臺南市將軍區", value: 725 },
  { text: "臺南市學甲區", value: 726 },
  { text: "臺南市北門區", value: 727 },
  { text: "臺南市新營區", value: 730 },
  { text: "臺南市後壁區", value: 731 },
  { text: "臺南市白河區", value: 732 },
  { text: "臺南市東山區", value: 733 },
  { text: "臺南市六甲區", value: 734 },
  { text: "臺南市下營區", value: 735 },
  { text: "臺南市柳營區", value: 736 },
  { text: "臺南市鹽水區", value: 737 },
  { text: "臺南市善化區", value: 741 },
  { text: "臺南市大內區", value: 742 },
  { text: "臺南市山上區", value: 743 },
  { text: "臺南市新市區", value: 744 },
  { text: "臺南市安定區", value: 745 },
  { text: "高雄市新興區", value: 800 },
  { text: "高雄市前金區", value: 801 },
  { text: "高雄市苓雅區", value: 802 },
  { text: "高雄市鹽埕區", value: 803 },
  { text: "高雄市鼓山區", value: 804 },
  { text: "高雄市旗津區", value: 805 },
  { text: "高雄市前鎮區", value: 806 },
  { text: "高雄市三民區", value: 807 },
  { text: "高雄市楠梓區", value: 811 },
  { text: "高雄市小港區", value: 812 },
  { text: "高雄市左營區", value: 813 },
  { text: "高雄市仁武區", value: 814 },
  { text: "高雄市大社區", value: 815 },
  { text: "高雄市岡山區", value: 820 },
  { text: "高雄市路竹區", value: 821 },
  { text: "高雄市阿蓮區", value: 822 },
  { text: "高雄市田寮區", value: 823 },
  { text: "高雄市燕巢區", value: 824 },
  { text: "高雄市橋頭區", value: 825 },
  { text: "高雄市梓官區", value: 826 },
  { text: "高雄市彌陀區", value: 827 },
  { text: "高雄市永安區", value: 828 },
  { text: "高雄市湖內區", value: 829 },
  { text: "高雄市鳳山區", value: 830 },
  { text: "高雄市大寮區", value: 831 },
  { text: "高雄市林園區", value: 832 },
  { text: "高雄市鳥松區", value: 833 },
  { text: "高雄市大樹區", value: 840 },
  { text: "高雄市旗山區", value: 842 },
  { text: "高雄市美濃區", value: 843 },
  { text: "高雄市六龜區", value: 844 },
  { text: "高雄市內門區", value: 845 },
  { text: "高雄市杉林區", value: 846 },
  { text: "高雄市甲仙區", value: 847 },
  { text: "高雄市桃源區", value: 848 },
  { text: "高雄市那瑪夏", value: 849 },
  { text: "高雄市茂林區", value: 851 },
  { text: "高雄市茄萣區", value: 852 },
  { text: "屏東縣屏東市", value: 900 },
  { text: "屏東縣三地門", value: 901 },
  { text: "屏東縣霧臺鄉", value: 902 },
  { text: "屏東縣瑪家鄉", value: 903 },
  { text: "屏東縣九如鄉", value: 904 },
  { text: "屏東縣里港鄉", value: 905 },
  { text: "屏東縣高樹鄉", value: 906 },
  { text: "屏東縣鹽埔鄉", value: 907 },
  { text: "屏東縣長治鄉", value: 908 },
  { text: "屏東縣麟洛鄉", value: 909 },
  { text: "屏東縣竹田鄉", value: 911 },
  { text: "屏東縣內埔鄉", value: 912 },
  { text: "屏東縣萬丹鄉", value: 913 },
  { text: "屏東縣潮州鎮", value: 920 },
  { text: "屏東縣泰武鄉", value: 921 },
  { text: "屏東縣來義鄉", value: 922 },
  { text: "屏東縣萬巒鄉", value: 923 },
  { text: "屏東縣崁頂鄉", value: 924 },
  { text: "屏東縣新埤鄉", value: 925 },
  { text: "屏東縣南州鄉", value: 926 },
  { text: "屏東縣林邊鄉", value: 927 },
  { text: "屏東縣東港鎮", value: 928 },
  { text: "屏東縣琉球鄉", value: 929 },
  { text: "屏東縣佳冬鄉", value: 931 },
  { text: "屏東縣新園鄉", value: 932 },
  { text: "屏東縣枋寮鄉", value: 940 },
  { text: "屏東縣枋山鄉", value: 941 },
  { text: "屏東縣春日鄉", value: 942 },
  { text: "屏東縣獅子鄉", value: 943 },
  { text: "屏東縣車城鄉", value: 944 },
  { text: "屏東縣牡丹鄉", value: 945 },
  { text: "屏東縣恆春鎮", value: 946 },
  { text: "屏東縣滿州鄉", value: 947 },
  { text: "澎湖縣馬公市", value: 880 },
  { text: "澎湖縣西嶼鄉", value: 881 },
  { text: "澎湖縣望安鄉", value: 882 },
  { text: "澎湖縣七美鄉", value: 883 },
  { text: "澎湖縣白沙鄉", value: 884 },
  { text: "澎湖縣湖西鄉", value: 885 },
  { text: "金門縣金沙鎮", value: 890 },
  { text: "金門縣金湖鎮", value: 891 },
  { text: "金門縣金寧鄉", value: 892 },
  { text: "金門縣金城鎮", value: 893 },
  { text: "金門縣烈嶼鄉", value: 894 },
  { text: "金門縣烏坵鄉", value: 896 },
  { text: "臺東縣臺東市", value: 950 },
  { text: "臺東縣綠島鄉", value: 951 },
  { text: "臺東縣蘭嶼鄉", value: 952 },
  { text: "臺東縣延平鄉", value: 953 },
  { text: "臺東縣卑南鄉", value: 954 },
  { text: "臺東縣鹿野鄉", value: 955 },
  { text: "臺東縣關山鎮", value: 956 },
  { text: "臺東縣海端鄉", value: 957 },
  { text: "臺東縣池上鄉", value: 958 },
  { text: "臺東縣東河鄉", value: 959 },
  { text: "臺東縣成功鎮", value: 961 },
  { text: "臺東縣長濱鄉", value: 962 },
  { text: "臺東縣太麻里", value: 963 },
  { text: "臺東縣金峰鄉", value: 964 },
  { text: "臺東縣大武鄉", value: 965 },
  { text: "臺東縣達仁鄉", value: 966 },
  { text: "花蓮縣花蓮市", value: 970 },
  { text: "花蓮縣新城鄉", value: 971 },
  { text: "花蓮縣秀林鄉", value: 972 },
  { text: "花蓮縣吉安鄉", value: 973 },
  { text: "花蓮縣壽豐鄉", value: 974 },
  { text: "花蓮縣鳳林鎮", value: 975 },
  { text: "花蓮縣光復鄉", value: 976 },
  { text: "花蓮縣豐濱鄉", value: 977 },
  { text: "花蓮縣瑞穗鄉", value: 978 },
  { text: "花蓮縣萬榮鄉", value: 979 },
  { text: "花蓮縣玉里鎮", value: 981 },
  { text: "花蓮縣卓溪鄉", value: 982 },
  { text: "花蓮縣富里鄉", value: 983 },
];

//註解:
//recruit : 公費生報名網站的domain
//map : 在地醫事人員地圖的domain
//platform : 在地醫事人員招生、輔導、分發及服務平臺的domain

export const devMenu = [
  "10001",  "10002",  "10003",  "10004",  "10005",  "10006",  "10010",  "10011",  "10012",  "10013",  "10014",  "10015",  "10016",  "10017",  
  "10018",  "10019",  "10020",  "10021",  "10022",  "10023",  "10024",  "10025",  "10026",  "10027",  "10028",  "10032",  "10033",  "10036",  
  "10037",  "10040",  "10041",  "10042",  "10043",  "10044",  "10045",  "20000",  "20001",  "20002",  "20003",  "20004",  "20005",  "20006",  
  "20010",  "20011",  "20012",  "20013",  "20014",  "20015",  "20016",  "20017",  "20018",  "20019",  "20020",  "20021",  "20022",  "20023",  
  "20024",  "20025",  "20026",  "20027",  "20028",  "20029",  "20030",  "20100",  "20102",  "20103",  "20104",  "20105",  "20106",  "20107",  
  "20108",  "20201",  "20202",  "20203",  "20204",  "20205",  "20206",  "20207",  "20208",  "20209",  "30001",  "30002",  "30003",  "30004",  
  "30005",  "30006",  "30010",  "30011",  "30012",  "30013",  "30014",  "30015",  "30016",  "30017",  "30021",  "30022",  "30023",  "30024",  
  "30025",  "30026",  "30027",  "30031",  "30032",  "30033",  "30034",  "30035",  "30036",  "30037",  "40002",  "40003",  "40004",  "40005",  
  "40006",  "40007",  "40008",  "40009",  "40010",  "40011",  "40012",  "40021",  "40022",  "40031",  "40032",  "40033",  "40034",  "40035",  
  "40036",  "40040",  "40041",  "40042",  "40043",  "40044",  "50001",  "50002",  "50003",  "50004",  "50005",  "50006",  "50007",  "50008",  
  "50009",  "5000A",  "5000B",  "5000C",  "5000D",  "5000E",  "5000F",  "5000G",  "5000H",  "5000I",  "5000J",  "5000K",  "5000L",  "5000N",  
  "5000S",  "5000T",  "5000U",  "5000X",  "5000Y",  "5000Z",  "50010",  "50020",  "50021",  "50030",  "50031",  "50032",  "50033",  "50034",  
  "50040",  "50041",  "50042",  "50050",  "50051",  "50052",  "50053",  "50054",  "50055",  "50056",  "50057",  "50058",  "50059",  "60001",  
  "60002",  "60003",  "60004",  "60005",  "60006",  "60010",  "60011",  "60012",  "60013",  "60014",  "60015",  "60016",  "60020",  "60021",  
  "70000",  "70001",  "70002",  "70003",  "70010",  "70011",  "70020",  "70021",  "80000",  "80001",  "80011",  "80012",  "90000",  "90001",  
  "90002",  "90003",  "90004",  "90010",  "90011",  "90012",  "90013",  "90014",  "90015",  "90016",  "90017",  "90020",  "90021",  "90022",  "90023",
];


