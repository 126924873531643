<template>
  <b-modal
    @shown="updateItem"
    title="個人資料維護"
    id="personalDataMaintain"
    size="lg"
  >
    <b-container fluid>
      <header class="my-dark">
        <b-input-group prepend="身分證字號" style="grid-column: 1/-1;">
          <b-input v-model="modalItem.Identifier" disabled></b-input>
        </b-input-group>
        <b-input-group prepend="姓名">
          <b-input v-model="modalItem.Name" disabled></b-input>
        </b-input-group>
        <b-input-group prepend="性別">
          <b-input v-model="modalItem.chineseGender" disabled></b-input>
        </b-input-group>
        <b-input-group prepend="出生日期">
          <b-input v-model="modalItem.chineseBirthDay" disabled></b-input>
        </b-input-group>

        <b-input-group prepend="地址" style="grid-column: 1/-1;">
          <b-input v-model="modalItem.Address"></b-input>
        </b-input-group>
        <b-input-group prepend="連絡電話" style="grid-column: 1/-1;">
          <b-input v-model="modalItem.Telphone"></b-input>
        </b-input-group>
        <b-input-group prepend="行動電話" style="grid-column: 1/-1;">
          <b-input v-model="modalItem.Mobile"></b-input>
        </b-input-group>
        <b-input-group prepend="E-MAIL" style="grid-column: 1/-1;">
          <b-input v-model="modalItem.Email"></b-input>
        </b-input-group>
      </header>
    </b-container>

    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="danger"
          class="float-right  mr-3"
          @click="$bvModal.hide('personalDataMaintain')"
          >取消</b-button
        >
        <b-button
          variant="success"
          class="float-right ml-3 mr-2"
          @click="saveInfo"
          >確定</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import { store } from "@/store/global.js";
import buildQuery from "odata-query";
const rows = [10, 20, 50];
export default {
  name: "personalDataMaintain",
  data() {
    return {
      personinfo: {},
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],

      modalItem: {
        Id: "",
        Account: "",
        Identifier: "",
        Name: "",
        Telphone: "",
        Mobile: "",
        GenderCode: "",
        BirthDate: "",
        Native: "",
        HomeTown: "",
        Address: "",
        Email: "",
        Category: "",
        CreateTime: "",
        CreateId: "",
        UpdateTime: "",
        UpdateId: "",
        IsActive: true,
        IsManager: true,
        LastActivityDate: "",
        HealthCode: "",
        Remark: "",
        Age: "",
        HealthName: "",
        RoleIds: [""],
      },
    };
  },
  computed: {},
  methods: {
    updateItem() {
      this.getData();
    },
    async saveInfo() {
      const url = `api/SysUser`;
      const obj = {
        ...this.modalItem,
      };
      try {
        await window.axios.put(url, obj);
        this.$bvToast.toast(`個人資料維護成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });

        this.$bvModal.hide("personalDataMaintain");
      } catch (e) {
        this.$bvToast.toast(`${e.response ? e.response.data : e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getData() {
      //odata3 qs
      let data = `${window.sessionStorage.hfjjj}`.replace(/\"/g, "");

      const url = `api/SysUser`;
      let qs = `?$filter=Identifier eq '${data}'`;

      try {
        const { Items } = await window.axios.get(url + qs);

        this.modalItem = JSON.parse(JSON.stringify(Items[0]));
        this.modalItem.chineseBirthDay = this.$twDate(this.modalItem.BirthDate);
        this.modalItem.chineseGender =
          this.modalItem.GenderCode == "M"
            ? "男"
            : this.modalItem.GenderCode == "F"
            ? "女"
            : "";
      } catch (e) {
        console.log(e);
      }
    },
  },

  mounted() {},
  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    // word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    word-break: keep-all;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-top: 1px solid #dee2e5;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
  }
  > div:first-child {
    border-left: 1px solid #dee2e5;
  }
  > div:last-child {
    // border-right: none;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 0;
    line-height: 46px;
    height: 46px;
  }
  .fee {
    text-align: right;
    padding-right: 10px;
  }
}
p {
  font-size: 2em;
  text-align: center;
}
.input-group-text {
  width: 100px;
  background: #0379fd;
  color: #fff;
}

div.input-group.special-col > div.input-group-prepend > div.input-group-text {
  width: 130px;
}
div.input-group.dtc-bg > div.input-group-prepend > div.input-group-text {
  background: #646969;
  color: #ffffff;
}

.input-group {
  margin-bottom: 10px;
}

::v-deep #personalDataMaintain > .modal-dialog > .modal-content > .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
// ::v-deep .modal-content {
//   margin-left: -180px;
//   width: 136%;
// }
.form-control[readonly] {
  background-color: #ffffff;
}
hr.new {
  border-top: 1px dashed #cacaca;
}
h4.title {
  font-size: 20px;
  font-weight: 700;
}
.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}
</style>
