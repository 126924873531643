<template>
  <div class="picker form-control">
    <div>
      <b-select
        class="data-select"
        v-model="year"
        :options="yearRange"
      ></b-select>
      <b-select
        class="data-select"
        v-model="month"
        :options="monthRange"
      ></b-select>
      <b-select
        class="data-select"
        v-model="day"
        :options="dayRange"
      ></b-select>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      yearRange: [],
      monthRange: [],

      dayRange: [],

      year: null,
      month: null,
      day: null,
      // time: null,
    };
  },
  props: ["time"],
  mounted() {
    const yearNow = new Date().getFullYear() - 2015;
    let yearsAll = new Array(250).fill().map((s, i) => yearNow + i);
    yearsAll.sort(function(a, b) {
      return b - a;
    });
    yearsAll.unshift({ value: null, text: "年", disabled: true });
    this.yearRange = [...yearsAll];

    // this.yearRange = [...Array(600).keys()].reduce(
    //   (c, a) => {
    //     c.push(+moment().format("YYYY") - a - 1421);
    //     return c;
    //   },
    //   [{ value: null, text: "年", disabled: true }]
    // );
    this.monthRange = [...Array(12).keys()].reduce(
      (c, a) => {
        c.push(a + 1);
        return c;
      },
      [{ value: null, text: "月", disabled: true }]
    );
    this.dayRange = [...Array(31).keys()].reduce(
      (c, a) => {
        c.push(a + 1);
        return c;
      },
      [{ value: null, text: "日", disabled: true }]
    );
    this.setNowTime();
  },
  watch: {
    year() {
      if (this.year && this.month && this.day) {
        this.$emit(
          "update",
          this.$twDate(`${this.year + 1911}-${this.month}-${this.day}`)
        );
      }
    },
    month() {
      if (this.year && this.month && this.day) {
        this.$emit(
          "update",
          this.$twDate(`${this.year + 1911}-${this.month}-${this.day}`)
        );
      }
    },
    day() {
      if (this.year && this.month && this.day) {
        this.$emit(
          "update",
          this.$twDate(`${this.year + 1911}-${this.month}-${this.day}`)
        );
      }
    },
  },

  methods: {
    setNowTime() {
      if (!Boolean(this.time)) {
        this.year = null;
        this.month = null;
        this.day = null;
        return;
      }
      let timeNow = this.time;

      this.year = timeNow.split("-")[0] ? +timeNow.split("-")[0] : null;
      this.month = timeNow.split("-")[1] ? +timeNow.split("-")[1] : null;
      this.day = timeNow.split("-")[2] ? +timeNow.split("-")[2] : null;
    },
  },
};
</script>
<style lang="scss" scoped>
.picker {
  > div {
    height: 100%;
    border: 0px solid black;
    cursor: pointer;
  }
  > div > select {
    margin-left: 5px;
    padding-left: 10px;
    padding-top: 3px;
    height: 95%;
    border: none;
    outline: none;
    width: 20%;
    cursor: pointer;
  }
  flex: 1 1;
  left: 0px;
  margin: 0px;
  padding: 0px;
}
.r-xy {
  position: absolute;
  right: 0px;
  top: 110%;
  z-index: 3;
}

.fa-calendar {
  float: right;
  height: 100%;
  margin-right: 15px;
}
.date-label {
  text-align: center;
  margin-top: 8px;
  font-weight: bold;
}
</style>
