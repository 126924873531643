<template>
  <div class="dtc-home">
    <PersonalDataMaintain></PersonalDataMaintain>
    <ProgressRecord></ProgressRecord>
    <ChangePassword></ChangePassword>
    <section class="dtx-msg-detail" v-if="showDetail">
      <div>
        <header
          style="cursor: pointer; font-size: 20px !important"
          @click="showDetail = false"
          class="pr-3"
        >
          <div>
            <img
              :src="images[0]"
              class="d-inlin-block"
              style="margin-top: -11px"
            />
            {{ msgObj.Subject }}
          </div>
          <div></div>
          <div style="font-size: 24px">X</div>
        </header>
        <main>
          <div class="dtc-detail">內容詳情</div>
          <div
            v-html="msgObj.Content"
            class="pl-4 pt-3 pr-2 dtc-max dtc-li"
          ></div>
          <div class="dtc-detail mt-2" v-if="msgObj.Remark">備註</div>
          <div
            v-html="msgObj.Remark"
            class="pl-4 pt-3 pr-2"
            v-if="msgObj.Remark"
          ></div>
          <div class="dtc-detail mt-2">創建者</div>
          <div v-html="msgObj.Creater" class="pl-4 pt-3 pr-2"></div>
          <div class="dtc-detail mt-2">發布時間</div>
          <div class="pl-4 pt-3 pr-2 mb-4">
            {{ $formatDate(msgObj.CreateTime) }}
          </div>
        </main>
      </div>
    </section>
    <div style="grid-column: 1 / span 1" class="dtx-board">
      <header
        class="dtx-header-top"
        style="background: #919191 !important"
      ></header>
      <header class="dtx-header">
        最新消息
        <span v-html="totalMsg"></span>
      </header>
      <main
        class="dtx-card-body dtx-posted"
        v-if="msgArr.length"
        style="background: white !important"
      >
        <div
          class="dtc-hover"
          v-for="(item, i) in msgArr"
          :key="i"
          :title="item.Subject"
          style="cursor: pointer; background: white !important"
          @click="showDetailMsg(item)"
        >
          <div style="background: white !important">
            {{ i + 1 }}. {{ item.Subject }}
          </div>
        </div>
      </main>
      <main class="dtx-card-body dtx-posted dtx-xx-msg" v-if="!msgArr.length">
        <section v-if="loadingApi" style="font-size: 23px">
          資料處理中
          <b-spinner type="grow" label="Spinning" />
          <b-spinner type="grow" label="Spinning" />
          <b-spinner type="grow" label="Spinning" />
        </section>
        <section v-if="!loadingApi && !this.errors" style="font-size: 23px">
          暫無資料
        </section>
        <section v-if="!loadingApi && this.errors" style="font-size: 23px">
          {{ this.errors }}
        </section>
      </main>
    </div>

    <div class="dtx-sys-control" id="dtc-60000" v-if="myAreaHasFunction">
      <header
        class="dtx-header-top"
        style="background: #8e80e8 !important"
      ></header>
      <header class="dtx-header" style="color: black !important">
        我的專區
      </header>
      <main class="dtx-card-body">
        <div
          @click="$bvModal.show('personalDataMaintain')"
          style="padding-top: 15px;cursor: pointer"
          v-if="allFunction.includes('60001')"
        >
          <div>
            <img src="personal.svg" style="width: 65px; height: 65px" />
          </div>
          <div class="dtx-mask2" :id="idArr[2]">個人資料維護</div>
        </div>

        <div
          @click="$router.push('myareaschoolpriority')"
          style="padding-top: 15px;cursor: pointer"
          v-if="allFunction.includes('60004')"
        >
          <div>
            <img src="distribution.svg" style="width: 65px; height: 65px" />
          </div>
          <div :id="idArr[3]">偏護菁英分發志願選填</div>
        </div>
        <!-- <div
          @click="clickMenu(60002, $event)"
          style="padding-top: 15px;cursor: pointer"
          v-if="allFunction.includes('60002')"
        >
          <div>
            <img src="password.svg" style="width: 65px; height: 65px" />
          </div>
          <div :id="idArr[4]">密碼修改</div>
        </div> -->
        <div
          @click="$bvModal.show('changePassword')"
          style="padding-top: 15px;cursor: pointer"
          v-if="allFunction.includes('60002')"
        >
          <div>
            <img src="password.svg" style="width: 65px; height: 65px" />
          </div>
          <div :id="idArr[4]">密碼修改</div>
        </div>
        <div
          @click="showProgressModal"
          style="padding-top: 15px;cursor: pointer"
          v-if="allFunction.includes('60003')"
        >
          <div>
            <img src="course.svg" style="width: 65px; height: 65px" />
          </div>
          <div :id="idArr[35]">服務歷程</div>
        </div>
        <div
          @click="$router.push('handandquestionmanage')"
          style="padding-top: 15px;cursor: pointer"
          v-if="allFunction.some((ai) => ['60010', '60014'].includes(ai))"
        >
          <div>
            <img src="hand.svg" style="width: 65px; height: 65px" />
          </div>
          <div :id="idArr[36]">大小手及問卷</div>
        </div>
        <div
          @click="$router.push('alldownloadfile')"
          style="padding-top: 15px;cursor: pointer"
          v-if="allFunction.some((ai) => ['60006', '60007'].includes(ai))"
        >
          <div>
            <img src="download.svg" style="width: 65px; height: 65px" />
          </div>
          <div :id="idArr[36]">下載專區</div>
        </div>
        <div
          @click="$router.push('electronicformManageTab')"
          style="padding-top: 15px;cursor: pointer"
          v-if="allFunction.some((ai) => ['60008', '60009'].includes(ai))"
        >
          <div>
            <img src="distribution.svg" style="width: 65px; height: 65px" />
          </div>
          <div :id="idArr[44]">分發電子表單</div>
        </div>
      </main>
    </div>
    <div class="dtx-sys-control" id="dtc-10000" v-if="admissionsServiceHasFunction">
      <header
        class="dtx-header-top"
        style="background: #6d95f7 !important"
      ></header>
      <header class="dtx-header" style="color: black !important">
        招生服務
      </header>
      <main class="dtx-card-body dtc-8">
        <div
          @click="$router.push('dtcvuetabs101')"
          style="padding-top: 15px !important;cursor: pointer"
          v-if="
            allFunction.some((ai) =>
              ['10040', '10041', '10042', '10043', '10044', '10045'].includes(
                ai
              )
            )
          "
        >
          <div>
            <img
              src="previous.svg"
              class="pt-0"
              style="width: 65px; height: 65px"
            />
          </div>
          <div id="ref3_1">招生前置作業</div>
        </div>
        <div
          @click="$router.push('Dtcvuetabs102')"
          style="padding-top: 15px !important;cursor: pointer"
          v-if="
            allFunction.some((ai) => ['10032', '10010', '10033'].includes(ai))
          "
        >
          <div class="no-padding">
            <img
              src="qualifications.svg"
              style="inline-block;width:65px;height:65px;"
              class="dtx-m-t-2"
            />
          </div>
          <div id="ref3_2">資格審查作業</div>
        </div>
        <div
          @click="$router.push('Dtcvuetabs103')"
          style="padding-top: 15px !important;cursor: pointer"
          v-if="
            allFunction.some((ai) =>
              ['10011', '10012', '10013', '10018'].includes(ai)
            )
          "
        >
          <div>
            <img src="selection.svg" style="width: 65px; height: 65px" />
          </div>
          <div id="ref3_3">甄試作業</div>
        </div>

        <div
          @click="$router.push('dtcvuetabs104')"
          style="padding-top: 15px !important;cursor: pointer"
          v-if="
            allFunction.some((ai) =>
              ['10014', '10015', '10016', '10017', '10037'].includes(ai)
            )
          "
        >
          <div class="no-padding">
            <img
              src="register.svg"
              class="pt-0"
              style="margin-top: -8px; width: 65px; height: 65px"
            />
          </div>
          <div id="ref3_4">分發報到作業</div>
        </div>
        <div
          @click="$router.push('dtcvuetabs105')"
          style="padding-top: 15px !important;cursor: pointer"
          v-if="allFunction.includes('10005')"
        >
          <img
            src="manage.svg"
            class="pt-0"
            style="width: 65px; height: 65px"
          />
          <div id="ref3_5">公佈欄管理</div>
        </div>
        <div
          @click="$router.push('dtcvuetabs106')"
          style="padding-top: 15px !important;cursor: pointer"
          v-if="
            allFunction.some((ai) =>
              [
                '10020',
                '10021',
                '10022',
                '10023',
                '10024',
                '10025',
                '10026',
                '10027',
              ].includes(ai)
            )
          "
        >
          <img
            src="setting.svg"
            class="pt-0"
            style="width: 65px; height: 65px"
          />
          <div id="ref3_6">基本資料設定</div>
        </div>
      </main>
    </div>

    <div class="dtx-buget-apply" id="dtc-20000" v-if="budgetRequestHasFunction">
      <header
        class="dtx-header-top"
        style="background: #6bb9a5 !important"
      ></header>
      <header class="dtx-header" style="color: #000 !important">
        預算申請
      </header>
      <main class="dtx-card-body">
        <div
          @click="$router.push('localbudgetmanagetab')"
          style="cursor: pointer"
          v-if="
            allFunction.some((ai) =>
              [
                '20011',
                '20012',
                '20015',
                '20016',
                '20013',
                '20014',
                '20017',
              ].includes(ai)
            )
          "
        >
          <img :src="images[13]" class="pt-2" />
          <div :id="idArr[13]">在地養成學校(含學士班及專師碩士班)</div>
        </div>

        <div
          @click="$router.push('ruralbudgetmanagetab')"
          style="cursor: pointer"
          v-if="
            allFunction.some((ai) =>
              ['20021', '20022', '20023', '20027', '20024', '20025'].includes(
                ai
              )
            )
          "
        >
          <img :src="images[14]" class="pt-2" />
          <div :id="idArr[14]">偏護菁英學校</div>
        </div>

        <div
          @click="$router.push('focusbudgetmanagetab')"
          style="cursor: pointer"
          v-if="
            allFunction.some((ai) =>
              [
                '20301',
                '20302',
                '20303',
                '20305',
                '20306',
              ].includes(ai)
            )
          "
        >
          <img :src="images[13]" class="pt-2" />
          <div :id="idArr[40]">重點科別培育</div>
        </div>
      </main>
    </div>
    <div
      class="dtx-sys-control"
      id="dtc-50000"
      v-if="systemManagementHasFunction"
    >
      <header
        class="dtx-header-top"
        style="background: #f08a7e !important"
      ></header>
      <header class="dtx-header" style="color: #000 !important">
        系統管理
      </header>
      <main class="dtx-card-body dtc-8">
        <div
          @click="$router.push('settingmanagetab')"
          style="cursor: pointer"
          v-if="
            allFunction.some((ai) =>
              [
                '5000C',
                '5000D',
                '5000E',
                '5000H',
                '5000I',
                '5000J',
                '5000K',
                '5000L',
                '5000Z',
              ].includes(ai)
            )
          "
        >
          <img :src="images[26]" class="pt-2" />
          <div :id="idArr[26]">基本資料</div>
        </div>

        <div
          @click="$router.push('authoritymanage')"
          style="cursor: pointer"
          v-if="
            allFunction.some((ai) =>
              ['50001', '50020', '50003', '50005'].includes(ai)
            )
          "
        >
          <img :src="images[27]" class="pt-2" />
          <div :id="idArr[27]">權限管理</div>
        </div>

        <div
          @click="$router.push('systemmanagetab1')"
          style="cursor: pointer"
          v-if="
            allFunction.some((ai) => ['50031', '50033', '50034'].includes(ai))
          "
        >
          <img
            src="nurse_distribution.svg"
            width="66"
            height="66"
            class="mt-2"
          />
          <div :id="idArr[1]">偏護菁英分發作業</div>
        </div>

        <div
          @click="$router.push('systemmanagetab2')"
          style="cursor: pointer"
          v-if="allFunction.some((ai) => ['50041', '5000S'].includes(ai))"
        >
          <img :src="images[28]" class="pt-0" />
          <div :id="idArr[28]">在地養成公費生資料維護</div>
        </div>
        <div
          @click="$router.push('ruralnursingdatamainten')"
          style="cursor: pointer"
          v-if="allFunction.includes('5000N')"
        >
          <img :src="images[29]" class="pt-1" />
          <div :id="idArr[29]">偏護菁英公費生資料維護</div>
        </div>
        <div
          @click="$router.push('systemmanagetab3')"
          style="cursor: pointer"
          v-if="allFunction.includes('50060')"
        >
          <img :src="images[28]" class="pt-0" />
          <div :id="idArr[39]">重點科別培育公費生資料維護</div>
        </div>
        <!-- <div @click="$router.push('studentchart')"> -->
        <div
          @click="$router.push('systemmanagetab4')"
          style="cursor: pointer"
          v-if="allFunction.some((ai) => ['50071', '5000P'].includes(ai))"
        >
          <img :src="images[28]" class="pt-0" />
          <div :id="idArr[41]">專科護理師碩士公費生資料維護</div>
        </div>
        <div
          @click="$router.push('systemmanagetab5')"
          style="cursor: pointer"
          v-if="allFunction.includes('50080')"
        >
          <img :src="images[29]" class="pt-0" />
          <div :id="idArr[42]">一般公費醫師制度公費生資料維護</div>
        </div>
        <div
          @click="$router.push('studentstatistical')"
          style="cursor: pointer"
          v-if="allFunction.includes('5000A')"
        >
          <img src="analysis.svg" width="66" height="69" class="pt-1" />
          <div id="analysisId" style="font-weight: 500; font-size: 16px">
            統計指標
          </div>
        </div>
        
        <div
          @click="$router.push('rptQueryLocal')"
          style="cursor: pointer"
          v-if="allFunction.includes('5000A')"
        >
          <img src="analysis.svg" width="66" height="69" class="pt-1" />
          <div id="analysisId2" style="font-weight: 500; font-size: 16px">
            報表查詢
          </div>
        </div>

      </main>
    </div>

    <div class="dtx-class-share" id="dtc-70000" v-if="courseSharingHasFunction">
      <header
        class="dtx-header-top"
        style="background: #ce9c81 !important"
      ></header>
      <header class="dtx-header" style="color: #000 !important">
        課程分享
      </header>
      <main class="dtx-card-body">
        <div
          @click="$router.push('localcoursemanage')"
          style="cursor: pointer"
          v-if="allFunction.some((ai) => ['70011', '70010'].includes(ai))"
        >
          <img :src="images[18]" class="pt-1" />
          <div :id="idArr[18]">在校課程</div>
        </div>
        <div
          @click="$router.push('nursecoursemanage')"
          style="cursor: pointer"
          v-if="allFunction.some((ai) => ['70002', '70020'].includes(ai))"
        >
          <img :src="images[19]" class="pt-1" />
          <div :id="idArr[19]">培訓課程</div>
        </div>
        <div
          @click="$router.push('courseMessageboard')"
          style="cursor: pointer"
          v-if="allFunction.includes('70005')"
        >
          <img src="announcement.svg" class="pt-2" />
          <div :id="idArr[44]">留言專區</div>
        </div>
      </main>
    </div>
    <div
      class="dtx-service-control"
      id="dtc-40000"
      v-if="serviceManagementHasFunction"
    >
      <header
        class="dtx-header-top"
        style="background: #89a855 !important"
      ></header>
      <header class="dtx-header" style="color: #000 !important">
        服務管理
      </header>
      <main class="dtx-card-body dtc-8">
        <div
          @click="$router.push('servicemanagetab1')"
          style="cursor: pointer"
          v-if="
            allFunction.some((ai) => ['40031', '40032', '30012'].includes(ai))
          "
        >
          <img src="service_manage.svg" class="pt-2" />
          <div :id="idArr[20]">服務歷程管理</div>
        </div>

        <div
          @click="$router.push('electronicreviewManageTab')"
          style="cursor: pointer"
          v-if="allFunction.some((ai) => ['40014', '40015'].includes(ai))"
        >
          <img src="hospital_manage.svg" class="pt-2" />
          <div :id="idArr[26]">分發申請書</div>
        </div>

        <div
          @click="$router.push('hospitalmanagetab1')"
          style="cursor: pointer"
          v-if="allFunction.some((ai) => ['40010', '40011'].includes(ai))"
        >
          <img src="hospital_manage.svg" class="pt-2" />
          <div :id="idArr[21]">偏護菁英分發醫院管理</div>
        </div>

        <div
          @click="$router.push('bulletinmaintain')"
          style="cursor: pointer"
          v-if="allFunction.includes('40007')"
        >
          <img src="announcement.svg" class="pt-2" />
          <div :id="idArr[23]">公告管理</div>
        </div>
        <div
          @click="$router.push('hcquestionnairemanage')"
          style="cursor: pointer"
          v-if="
            allFunction.some((ai) =>
              ['40040', '40041', '40042', '40043', '40044', '40045'].includes(ai)
            )
          "
        >
          <img src="vacancy.svg" class="pt-2" />
          <div :id="idArr[24]">衛生所缺額管理</div>
        </div>
        <div
          @click="$router.push('hcquestionnairecentermanage')"
          style="cursor: pointer"
          v-if="allFunction.includes('40009')"
        >
          <img src="list.svg" class="pt-2" />
          <div :id="idArr[25]">衛生所缺調查表</div>
        </div>
        <div
          @click="$router.push('supportreporting')"
          style="cursor: pointer"
          v-if="allFunction.includes('40012')"
        >
          <img src="list.svg" class="pt-2" />
          <div :id="idArr[26]">支援報備線上確認功能</div>
        </div>
      </main>
    </div>
    <div
      class="dtx-service-control"
      id="dtc-90000"
      v-if="counselingAreaHasFunction"
    >
      <header
        class="dtx-header-top"
        style="background: #eda775 !important"
      ></header>
      <header class="dtx-header" style="color: #000 !important">
        輔導專區
      </header>
      <main class="dtx-card-body dtc-8">
        <div
          @click="$router.push('localrecordmanage')"
          style="cursor: pointer"
          v-if="
            allFunction.some((ai) =>
              [
                '90011',
                '90012',
                '90013',
                '90014',
                '90015',
                '90016',
                '90017',
              ].includes(ai)
            )
          "
        >
          <img src="coach.svg" style="width: 50px; height: 50px" />
          <div :id="idArr[30]">
            <span>輔導委辦單位</span>
            <br />
            <span>(在地養成)</span>
          </div>
        </div>

        <div
          @click="$router.push('nurserecordmanage')"
          style="cursor: pointer"
          v-if="allFunction.some((ai) => ['90021', '90022'].includes(ai))"
        >
          <img src="coach-nurse.svg" style="width: 50px; height: 50px" />
          <div id="ref10_5">
            <span>輔導委辦單位</span>
            <br />
            <span>(偏護精英)</span>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import queryString from "query-string";
import { store } from "@/store/global.js";
import ProgressRecord from "@/components/systemmanage/ProgressRecord";
import PersonalDataMaintain from "@/components/PersonalDataMaintain.vue";
import ChangePassword from "@/components/ChangePassword.vue";
const ids = new Array(9)
  .fill()
  .map((s, i) => 10000 * (i + 1))
  .map(String);
const str =
  "衛生福利部108學年度｢原住民族及離島地區醫事人員養成計畫｣學士班公費生甄試入學統一分發名單。衛生局發佈。108.12.12";
const posted = new Array(20).fill(str);
const titles = [
  "我的專區",
  "招生服務",
  "預算申請",
  "訓練服務",
  "課程分享",
  "服務管理",
  "系統管理",
  "輔導專區",
];
import { countryObject, hometownObject } from "@/constant.js";
export default {
  name: "home",
  data() {
    return {
      countryObject,
      hometownObject,
      allFunction: [],
      errors: "",
      totalMsg: "",
      loadingApi: false,
      showDetail: false,
      msgObj: {},
      msgArr: [],
      account: "",
      schoolCode: "",
      map: "",
      posted,
      titles,
      idArr: [
        "ref10_5",
        "refk_5",
        "ref2_1",
        "ref2_2",
        "ref2_3",
        "ref3_1",
        "ref3_2",
        "ref3_3",
        "ref3_4",
        "ref3_5",
        "ref3_6",
        "ref3_7",
        "ref3_8",
        "ref4_1",
        "ref4_2",
        "ref5_1",
        "ref5_2",
        "ref5_3",
        "ref6_1",
        "ref6_2",
        "ref7_1",
        "ref7_2",
        "ref7_3",
        "ref7_4",
        "ref7_5",
        "ref7_6",
        "ref7_7",
        "ref8_1",
        "ref8_2",
        "ref8_3",
        "ref8_4",
        "ref9_1",
        "ref10_1",
        "ref10_2",
        "ref10_3",
        "ref10_4",
        "ref2_4",
        "ref2_5",
        "analysisId",
        "ref2_6",
        "ref8_5",
        "ref4_3",
        "ref11_1",
        "ref12_1",  //42
        "refelectronic",
        "ref6_3",
      ],
      job1Img: require("./h/9-01.png"),
      job2Img: require("./h/9-02.png"),
      modImg: require("./h/0_7-2-01.png"),
      helpImg: require("./h/9_9.png"),
      plus1: require("./h/0_3-9.png"),
      plus2: require("./h/0_3-10.png"),
      plus3: require("./h/0_3-5.png"),
      images: [
        require("./h/0_1-1.png"),
        require("./h/0_1-2.png"),
        require("./h/0_2-1.png"),
        require("./h/0_2-2.png"),
        require("./h/0_2-3.png"),
        require("./h/0_3-1.png"),
        require("./h/0_3-2.png"),
        require("./h/0_3-3.png"),
        require("./h/0_3-4.png"),
        require("./h/0_3-5.png"),
        require("./h/0_3-6.png"),
        require("./h/0_3-7.png"),
        require("./h/0_3-8.png"),
        require("./h/0_4-1.png"),
        require("./h/0_4-2.png"),
        require("./h/0_5-1.png"),
        require("./h/0_5-2.png"),
        require("./h/0_5-3.png"),
        require("./h/0_6-1.png"),
        require("./h/0_6-2.png"),
        require("./h/0_7-1.png"),
        require("./h/0_7-2.png"),
        require("./h/0_7-3.png"),
        require("./h/0_7-4.png"),
        require("./h/0_7-5.png"),
        require("./h/0_7-6.png"),
        require("./h/0_8-1.png"),
        require("./h/0_8-2.png"),
        require("./h/0_8-3.png"),
        require("./h/0_8-4.png"),
        require("./h/0_9-1.png"),
      ],
      isAdminCodeCheck: false,
      isSchoolCodeCheck: false,
      isCanView: false,
    };
  },
  computed: {
    alreadyCheck() {
      return store.isAlreadyCheckInfo;
    },
    myAreaHasFunction() {
      return this.allFunction.some((ai) =>
        [
          "60001",
          "60004",
          "60002",
          "60003",
          "60006",
          "60007",
          "60010",
          "60014",
          "60008",
          "60009",
        ].includes(ai)
      );
    },
    admissionsServiceHasFunction() {
      return this.allFunction.some((ai) =>
        [
          "10040",
          "10041",
          "10042",
          "10043",
          "10044",
          "10045",
          "10032",
          "10010",
          "10033",
          "10011",
          "10012",
          "10013",
          "10018",
          "10014",
          "10015",
          "10016",
          "10017",
          "10037",
          "10005",
          "10020",
          "10021",
          "10022",
          "10023",
          "10024",
          "10025",
          "10026",
          "10027",
        ].includes(ai)
      );
    },
    budgetRequestHasFunction() {
      return this.allFunction.some((ai) =>
        [
          "20011",
          "20012",
          "20015",
          "20016",
          "20013",
          "20014",
          "20017",
          "20021",
          "20022",
          "20023",
          "20027",
          "20024",
          "20025",
          "20301",
          "20302",
          "20303",
          "20305",
          "20306"
        ].includes(ai)
      );
    },
    systemManagementHasFunction() {
      return this.allFunction.some((ai) =>
        [
          "5000C",
          "5000D",
          "5000E",
          "5000H",
          "5000I",
          "5000J",
          "5000K",
          "5000L",
          "5000Z",
          "50001",
          "50020",
          "50003",
          "50005",
          "50031",
          "50033",
          "50034",
          "5000S",
          "50041",
          "5000N",
          "5000A",
          "50060",
          "99900",
          "5000P",
          "50071",
          "50080",
        ].includes(ai)
      );
    },

    courseSharingHasFunction() {
      return this.allFunction.some((ai) =>
        ["70011", "70010", "70020", "70021"].includes(ai)
      );
    },
    serviceManagementHasFunction() {
      return this.allFunction.some((ai) =>
        [
          "40031",
          "40032",
          "30012",//目前先用40012，by 2023-12-24
          "40010",
          "40011",
          "40012",
          "40007",
          "40040",
          "40041",
          "40042",
          "40043",
          "40044",
          "40045",
          "40009",
        ].includes(ai)
      );
    },
    counselingAreaHasFunction() {
      return this.allFunction.some((ai) =>
        [
          "90011",
          "90012",
          "90013",
          "90014",
          "90015",
          "90016",
          "90017",
          "90021",
          "90022",

        ].includes(ai)
      );
    },
  },
  components: { PersonalDataMaintain, ProgressRecord, ChangePassword },

  methods: {
    async init() {
      this.loadingApi = true;
      try {
        //set 最新消息
        //  isAdminCodeCheck: false,
        //  isSchoolCodeCheck: false,
        let arr1 = [];
        if (!this.isAdminCodeCheck) {
          const loginHealthCode = sessionStorage
            .getItem("darcok")
            .replace(/\"/g, "");

          const url = `Bulletin/Get?healthcode=${loginHealthCode}`;
          const res1 = await window.axios.get(url);
          arr1 = JSON.parse(JSON.stringify(res1));
        }
        const arr2 = await window.axios.get(`Bulletin/Get?healthcode=00`);
        const arr3 = await window.axios.get(`Bulletin/Get?healthcode=01`);
        const arr4 = await window.axios.get(`Bulletin/Get?healthcode=02`);
        const arr5 = await window.axios.get(`Bulletin/Get?healthcode=03`);

        let msgs = [];
        if (this.isSchoolCodeCheck || this.isAdminCodeCheck) {
          msgs = [...arr1, ...arr2, ...arr3, ...arr4, ...arr5];
        } else {
          msgs = [...arr1];
        }

        if (msgs.length) this.totalMsg = `(共${msgs.length}筆)`;
        this.msgArr = msgs;

        this.loadingApi = false;

        this.msgObj = msgs[0];

        const map = await window.axios.get("School/GetAll");
        localStorage.dtcSchoolList = JSON.stringify(map);
      } catch (e) {
        this.errors = "Server " + e;
      } finally {
        this.loadingApi = false;
      }
    },
    showDetailMsg(item) {
      this.msgObj = item;
      this.showDetail = true;
    },
    clickMenu(id, evt) {
      try {
        window.parent.Ext.fireEvent("vueMenuId", id);
      } catch (e) {
      } finally {
        const obj = { MenuId: "" + id };
        const str = JSON.stringify(obj);
        window.dtxChan.call({
          method: "clickMenuId",
          params: `${str}`,
          success: function(v) {
            console.log(v);
          },
        });
      }
    },
    openPrintPage(n, evt) {
      if (n == 1) {
        window.dtcDebug ? this.$router.push("doc5") : "";
        this.clickMenu("20010");
      } else {
        window.dtcDebug ? this.$router.push("doc8-4") : "";
        this.clickMenu("20020");
      }
    },
    async setInstitutionId() {
      const url1 = `SysRole/GetRoleInstitutions?no=Admin`;
      const url2 = `SysRole/GetRoleInstitutions?no=Hospital1`;
      const url3 = `SysRole/GetRoleInstitutions?no=Supervisor`;
      const url4 = `SysRole/GetRoleInstitutions?no=user1`;
      const url5 = `SysRole/GetRoleInstitutions?no=user3`;
      const url6 = `SysRole/GetRoleInstitutions?no=user4`;

      if (!Boolean(sessionStorage.roleOtal)) {
        let arr1 = await window.axios.get(url1);
        arr1 = arr1.map((s) => s.No);
        let arr3 = await window.axios.get(url3);
        arr3 = arr3.map((s) => s.No);
        const adminAll = [...arr1, ...arr3];
        sessionStorage.setItem("roleOtal", JSON.stringify(adminAll));
      }
      if (!Boolean(sessionStorage.opsHpital)) {
        let arr2 = await window.axios.get(url2);
        arr2 = arr2.map((s) => s.No);
        sessionStorage.setItem("opsHpital", JSON.stringify(arr2));
      }
      if (!Boolean(sessionStorage.roleHealthBureau)) {
        let arr4 = await window.axios.get(url4);
        arr4 = arr4.map((s) => s.No);
        sessionStorage.setItem("roleHealthBureau", JSON.stringify(arr4));
      }
      if (!Boolean(sessionStorage.stuChool)) {
        let arr5 = await window.axios.get(url5);
        arr5 = arr5.map((s) => s.No);
        sessionStorage.setItem("stuChool", JSON.stringify(arr5));
      }
      if (!Boolean(sessionStorage.roleHealthCenter)) {
        let arr6 = await window.axios.get(url6);
        arr6 = arr6.map((s) => s.No);
        sessionStorage.setItem("roleHealthCenter", JSON.stringify(arr6));
      }
    },
    async showProgressModal() {
      //id
      const data = `${window.sessionStorage.hfjjj}`.replace(/\"/g, "");
      const qs = `?$filter=Identifier eq '${data}'`;
      const url = `StudentInfo/GetStudentService${qs}`;
      let { Items } = await window.axios.get(url);
      Items = Items.map((s) => ({
        status: s.ServiceItem || "",
        servicenow: s.ServiceDetail || "",
        name: s.Name || "",
        id: s.Identifier || "",
        gender: !Boolean(s.Gender) ? "" : s.Gender == "M" ? "男" : "女",
        birthday: s.Cht_BirthDate || "",
        // school: this.schoolObject.find((k) => +k.value == +s.SchoolCode)
        //   ? this.schoolObject.find((k) => +k.value == +s.SchoolCode).text
        //   : "",
        entryYear: s.StartYear || "",
        graduateYear: s.EndYear || "",
        domicile: Boolean(s.Category)
          ? this.countryObject.find((k) => +k.value == +s.Category).text
          : "",
        hometown: this.hometownObject.find((k) => k.value == +s.County)
          ? this.hometownObject.find((k) => k.value == +s.County).text
          : "",
        contactAddress: s.ContAddress || "",
        homeAddress: s.ResidenceAddrs || "",
        telphone: s.Home || "",
        mobilephone: s.Mobile || "",
        email: s.Email || "",
        remark: Boolean(s.Note) ? s.Note.replace(/\n/g, "") : "",
        originRemark: s.Note || "",
        code: s.Code || "",
        majorYear: s.MajorYear || "",
        majorName: s.MajorName || "",
        originAllData: s,
        cat: s.MajorName || "",
        type: +s.Category || "",
        pgyTime: s.PGY_Dates || "無",
        trainingTime: s.Expert_Dates || "無",
        serviceTime: s.Service_Dates || "無",
      }));

      const res = JSON.parse(JSON.stringify(Items[0]));
      store.studentProgressInfo = { ...res };
      this.$bvModal.show("studentProgress");
    },
    async setInfoCount() {
      if (this.alreadyCheck) {
        return;
      }
      if (!this.isCanView) {
        return;
      }
      let id = "";
      let qs = "";
      if (this.isSchoolCodeCheck) {
        id = `${window.sessionStorage.docrkkt}`.replace(/\"/g, "");
        qs = `${qs}?$filter=Identifier eq '${id}'`;
      }
      const url = `api/studentServiceInfo`;
      try {
        const { Count } = await window.axios.get(url + qs);
        store.totalAllInfoCount = +Count;
      } catch (e) {
        console.log(e);
      }
    },
    setCheck() {
      const loginHealthCode = sessionStorage
        .getItem("darcok")
        .replace(/\"/g, "");
      const roleOtal = JSON.parse(sessionStorage.getItem("roleOtal"));

      this.isAdminCodeCheck = roleOtal.includes(loginHealthCode) ? true : false;
      const stuChool = JSON.parse(sessionStorage.getItem("stuChool"));
      this.isSchoolCodeCheck = stuChool.includes(loginHealthCode)
        ? true
        : false;
      const all = [...roleOtal, ...stuChool];
      this.isCanView = all.includes(loginHealthCode) ? true : false;
    },
  },
  async created() {
    // alert(this.idArr.length);
  },
  async mounted() {
    //setAccountName
    store.accountName = Boolean(sessionStorage.fullname)
      ? `${window.sessionStorage.fullname}`.replace(/\"/g, "")
      : "";
    this.allFunction = Boolean(sessionStorage.functionIds)
      ? JSON.parse(sessionStorage.getItem("functionIds"))
      : [];
      //console.log(this.allFunction);//20240722可確認functionId
    await this.setInstitutionId();
    await this.setCheck();
    await this.setInfoCount();
    await this.init();
  },
};
</script>

<style lang="scss">
:root {
  --dtx-board-header-top-bg: var(--warning);
  --dtx-card-header-top: 12px;
  --dtx-card-header-height: 42px;
  --dtx-card-header-bg: #d9d9db;
  --dtx-card-body-bg: #fafafa;
  --dtx-card-header-font-size: 18px;
  --dtx-card-body-font-size: 15px;
  --dtx-card-color: var(--light);
}

.dtx-m-t-2 {
  margin-top: -8px;
}

.dtc-hover{
  background: #fafafa !important;

}
.dtc-max {
  max-height: 500px !important;
  overflow-y: auto;
}
.dtx-msg-detail {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(#000, 0.6);
  display: grid;
  place-items: center;
  z-index: 9999;
  > div {
    border-radius: 5px;
    width: 80vw;
    min-height: 40vw;
    max-height: 90vw;
    background: var(--light);
    color: var(--dark) !important;
    header,
    .dtc-detail {
      height: 60px;
      background: var(--warning);
      color: black;
      border-radius: 5px;
      padding-left: 10px;
      line-height: 60px;
    }
    header {
      display: grid;
      grid-template-columns: max-content 1fr max-content;
    }
    .dtc-detail {
      background: var(--primary);
      height: 30px;
      line-height: 30px;
      color: white;
    }
  }
}

.dtc-home {
  position: relative;
  --min-height: 211px;
  padding-top: 13px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  background: #e1e1e1;
  display: grid;
  grid-template-columns: repeat(3, minmax(500px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(249px, 489px));//原先為349現為489
  grid-gap: 2rem;
  top: 65px;
  > div {
    // max-height: 349px;
    // min-height: 349px;
    border-radius: 5px;
    margin-bottom: 1rem;
    background: #f8f8f8;

  }
  .dtc-min-height {
    height: var(--min-height);
    .dtx-header-top {
      background: #cc33ff;
    }
    .dtx-card-body {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1rem;
      > div:first-child {
        height: 120px;
        background: white;
      }
    }
  }
}
.dtx-header-top {
  border-radius: 5px;
}
.dtx-header {
  background: #efefef !important;
  height: var(--dtx-card-header-height);
  border-radius: 5px;
  line-height: var(--dtx-card-header-height);
  font-size: var(--dtx-card-header-font-size);
  color: black !important;
  padding-left: 12px;
}
.dtx-header-top {
  height: 12px;
  background: var(--dtx-board-header-top-bg);
}

.dtx-card-body {
  margin: 1rem;
  border-radius: 5px;
  min-height: 246px;
  min-width: 450px; // added art July-3rd
}

.dtx-board {
  .dtx-header-top {
     background: #4F4090;
  }
  .dtx-card-body {
    background: white// ;#f4d392;
  }
}

.dtx-my-area {
  .dtx-header-top {
    background: #4F4090;
  }
  .dtx-card-body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    > div {
      cursor: pointer;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 60px 50px;
      background: #fafafa; //#9c8eef;
    }
  }
}

.small-title {
  font-size: 18px;
  transform: translateY(0px);
}

.sub-sub-title {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0;
  width: 100%;
  height: 100%;
  display: block;
  margin-top: 99px;
  font-size: 13px;
}
.dtx-enroll-service {
  .dtx-header-top {
     background: #4F4090;
  }
  .dtx-card-body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    //grid-template-rows: repeat(2, 1fr);
    grid-gap: 1rem;
    > div {
      cursor: pointer;
      background: #fafafa; //#9c8eef; //lighten(#007bff, 25%);
      height: 115px;
      border-radius: 5px;
    }
  }
}
.dtx-buget-apply {
  .dtx-header-top {
     background: #4F4090;
  }

  .dtx-card-body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    > div {
      cursor: pointer;
      background: #fafafa; //#9c8eef;
      height: 245px;
      border-radius: 5px;
    }
  }
}
.dtx-train-service {
  .dtx-header-top {
    background: #4F4090;
  }
  .dtx-card-body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    > div {
      cursor: pointer;
      background:white;
      height: 245px;
    }
  }
}

.dtx-card-body {
  color: black;
}
.dtx-class-share {
  .dtx-header-top {
    background: #4F4090;
  }

  .dtx-card-body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    > div {
      cursor: pointer;
      background: #fafafa; //#9c8eef;
      height: 245px;
      border-radius: 5px;
    }
  }
}

.dtx-find-job {
  .dtx-header-top {
     background: #4F4090;
  }
  .dtx-card-body > div {
    background: #fafafa; //#9c8eef;
  }
  .dtx-card-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    grid-template-areas:
      "a1 a2"
      "a3 a3";
  }
}
.a1 {
  grid-area: a1;
  height: 130px !important;
  cursor: pointer;
  position: relative;
  img {
    object-fit: cover;
    //height: 80px;
  }
}
.a2 {
  grid-area: a2;
  object-fit: cover;
  height: 130px !important;
  cursor: pointer;
  position: relative;
  img {
    object-fit: cover;
    //height: 80px;
  }
}

.a3 {
  grid-area: a3;
  height: 120px !important;
  display: grid;
  justify-content: center;
  cursor: pointer;
  > div:first-child {
    margin-top: -20px;
    cursor: pointer;
    //font-size: 20px;
  }
}

.dtx-service-control {
  .dtx-header-top {
    background: #4F4090;
  }
  .dtx-card-body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    //grid-template-rows: repeat(2, 1fr);
    grid-gap: 1rem;
    > div {
      //color: var(--light);
      cursor: pointer;
      background: #fafafa; //#9c8eef;
      height: 115px;
      border-radius: 5px;
    }
  }
}
.dtx-sys-control {
  .dtx-header-top {
     background: #4F4090;
  }
  .dtx-card-body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    //grid-template-rows: repeat(2, 1fr);
    grid-gap: 1rem;
    > div {
      cursor: pointer;
      background: #fafafa; //#9c8eef;
      height: 115px;
      border-radius: 5px;
    }
  }
}

.dtc-8 > div {
  padding-top: 6px !important;
}

.dtx-card-body > div {
  padding-top: 30px;
  position: relative;
  border-radius: 5px;
  text-align: center;
  z-index: 1;
  background: #f1f1f1 !important;//#9c8eef;
  img {
    display: inline-block;
    z-index: 1;
  }

  .dtx-mask::after {
    position: absolute;
    z-index: 99;
    content: "";
    display: block;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(#000, 0.6);
    cursor: not-allowed !important;
    border-radius: 5px;
  }
}

.no-padding {
  padding-top: 12px;
}

.dtx-posted {
  padding-top: 0px;
  max-height: 246px;
  overflow-y: auto;
  > div {
    padding-top: 5px;
    padding-left: 5px;
    height: 33px;
    font-size: 1rem;
    display: grid;
    text-align: left;
    grid-template-columns: 1fr;
    grid-column-gap: 12px;
    margin-bottom: 12px;
    > div:last-child {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.dtx-xx-msg {
  display: grid;
  place-items: center;
}

//  grid-template-columns: repeat(auto-fit, minmax(200px, 496px));
@media (max-width: 1580px) {
  .dtc-home {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 900px) {
  .dtc-home {
    grid-template-columns: 1fr !important;
    place-items: center;
    > div {
      max-width: 99vw;
      min-width: 99vw;
      width: 99vw;
    }
  }
  .dtx-card-body {
    margin: 1rem;
    border-radius: 5px;
    min-height: 246px;
    min-width: 350px; // added art July-3rd
  }
}

.dtx-sys-control_4{
 .dtx-card-body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.dtc-li{
   li{
    margin-left:10px;
  }
}
</style>

<style lang="scss">
.vue-tabs .nav-tabs .active_tab {
  color: white !important;
  background: #6d58c7 !important;
}
</style>
