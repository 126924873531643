<template>
  <div class="picker form-control">
    <div @click="isShow = !isShow">
      <input
        style="outline: none;"
        type="text"
        v-model="time"
        readonly
        placeholder="日期設定"
      />
      <i class="fas fa-calendar"></i>
    </div>
    <div class="r-xy" v-show="isShow">
      <v-calendar id="only-calendar" locale="zh-TW" @dayclick="onDayClick">
        <template v-slot:header="{ year, month }">
          <div class="date-label">{{ `${year - 1911} 年 ${month} 月` }}</div>
        </template>
      </v-calendar>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isShow: false,
    };
  },
  props: ["time"],
  methods: {
    onDayClick(input) {
      const { year, month, day } = input;
      this.$emit("update", this.$twDate(`${year}-${month}-${day}`));
      setTimeout(() => {
        this.isShow = false;
      }, 20);
    },
  },
};
</script>
<style lang="scss" scoped>
.picker {
  > div {
    height: 100%;
    border: 0px solid black;
    cursor: pointer;
  }
  > div > input {
    padding-left: 10px;
    width: 75%;
    height: 100%;
    border: 0px solid red;
    line-height: 1.5em;
    cursor: pointer;
  }
  flex: 1 1;
  left: 0px;
  margin: 0px;
  padding: 0px;
}
.r-xy {
  position: absolute;
  right: 0px;
  top: 110%;
  z-index: 3;
}

.fa-calendar {
  float: right;
  height: 100%;
  margin-right: 15px;
}
.date-label {
  text-align: center;
  margin-top: 8px;
  font-weight: bold;
}
</style>
