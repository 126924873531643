<template>
  <section class="dtc-main-section">
    <h4 class="big-title">
      <span class="icon"><i class="fas fa-graduation-cap"></i></span>
      醫事人員證書
    </h4>
    <header class="dtc-grid-header my-dark main-grid1">
      <div v-for="(item, i) in headers" :key="`headers1${i}`" :title="item">
        {{ item.name || item }}
        <span v-show="item.sortDesc === null">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      v-if="!items.length"
      class="mt-2"
      style="grid-column: 1/-1;font-size:26px;text-align:center;font-weight:600"
    >
      暫無資料
    </main>
    <main
      class="dtc-grid-header2 main-grid1"
      v-for="(item, i) in items"
      :key="`content1${i}`"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#EDEDED ;' : 'background-color: #DDDDDD;'
      "
    >
      <div>{{ item.cer_ref_id || "暫無資料" }}</div>
      <div>{{ item.ref_name || "暫無資料" }}</div>
      <div>{{ item.cer_doc_id || "暫無資料" }}</div>
      <div>{{ item.cer_doc_no || "暫無資料" }}</div>
      <div :title="item.cet_issue_date">{{ item.cet_issue_date || "" }}</div>
      <div>{{ item.cet_approve_id || "暫無資料" }}</div>
      <div>{{ item.cet_approve_no || "暫無資料" }}</div>
      <div :title="item.cet_approve_date">
        {{ item.cet_approve_date || "暫無資料" }}
      </div>
      <div>{{ item.cet_reason_m || "暫無資料" }}</div>
      <div>{{ item.cer_flag || "暫無資料" }}</div>
      <div>{{ item.master_flag || "暫無資料" }}</div>
    </main>
    <h4 class="big-title mt-5">
      <span class="icon"><i class="fas fa-graduation-cap"></i></span>
      國考歷程-專科證照管理
    </h4>
    <header class="dtc-grid-header my-dark main-grid2">
      <div v-for="(item, i) in headers2" :key="`headers2${i}`" :title="item">
        {{ item.name || item }}
        <span v-show="item.sortDesc === null">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      v-if="!items2.length"
      class="mt-2"
      style="grid-column: 1/-1;font-size:26px;text-align:center;font-weight:600"
    >
      暫無資料
    </main>

    <main
      class="dtc-grid-header2 main-grid2"
      v-for="(item2, i) in items2"
      :key="`content2${i}`"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#EDEDED ;' : 'background-color: #DDDDDD;'
      "
    >
      <div>{{ item2.spc_sdw_id || "暫無資料" }}</div>
      <div>{{ item2.sdw_name || "暫無資料" }}</div>
      <div>{{ item2.sdw_dept_name || "暫無資料" }}</div>
      <div>{{ item2.spc_lic_edate || "暫無資料" }}</div>
      <div>{{ item2.spc_flag || "暫無資料" }}</div>
    </main>
  </section>
</template>

<script>
import queryString from "query-string";
import buildQuery from "odata-query";
import { store } from "@/store/global.js";
import moment from "moment";

const headers = [
  "醫事人員類別(代碼)",
  "醫事人員類別",
  "證書字號(字)",
  "證書字號(號)",
  "證書核發日期",
  "核准文號(字)",
  "核准文號(號)",
  "核准文號日期",
  "申請原因",
  "證書狀態",
  "主要證書註記",
];

const headers2 = [
  "專科字號(代碼)",
  "專科字別",
  "專科證書字號(字)",
  "有效日期(訖)",
  "是否有效",
];
const zero = "T00:00:00";
const rows = [10, 20, 50];
const year = new Date().getFullYear() - 1911;
let years = new Array(9).fill().map((s, i) => year + 1 - i);
years.unshift({ value: null, text: "全部" });

export default {
  name: "downarea",
  data() {
    return {
      headers,
      headers2,
      items: [],
      items2: [],

      orderBy: [],
      year: null,
      search: true,
      rows,
      totalCountStr: "",
    };
  },
  components: {},
  computed: {},

  methods: {
    async getData() {
      const id = store.studentProgressInfo.id;
      const url = `StudentInfo/GetNationalExamDetail?Identifier=${id}`;

      try {
        const res = await window.axios.get(url);
        this.items = res.License ? [...res.License] : [];
        this.items2 = res.ExpertLicense ? [...res.ExpertLicense] : [];
        if (this.items.length) {
          this.items = this.items.map((s) => {
            s.cet_issue_date = s.cet_issue_date
              ? this.$twDate2(moment(s.cet_issue_date).format("YYYY-MM-DD"))
              : "";
            s.cet_approve_date = s.cet_approve_date
              ? this.$twDate2(moment(s.cet_approve_date).format("YYYY-MM-DD"))
              : "";
            return s;
          });
        }
        if (this.items2.length) {
          this.items2 = this.items2.map((s) => {
            s.spc_lic_edate = s.spc_lic_edate
              ? this.$twDate2(moment(s.spc_lic_edate).format("YYYY-MM-DD"))
              : "";
            return s;
          });
        }
      } catch (e) {
        this.$bvToast.toast(e + ``, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
  },
  async mounted() {
    this.getData();
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  overflow-x: auto;
  border-radius: 5px 5px 0 0;
  margin-top: 20px;
  position: relative;
  height: 50vh;
  background: #f7c11fa4;
  width: 100%;
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    /* word-break: keep-all; */
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
    display: -webkit-box;
    height: 36px;
  }
  > div:first-child {
    border-left: 0px;
  }
  > div:last-child {
    border-right: 0px;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}
.main-grid1 {
  grid-template-columns: 168px repeat(9, 144px) repeat(1, 1fr);
}
.main-grid2 {
  grid-template-columns: repeat(5, 1fr);
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #424242;
    color: white;
  }
}
.dtc-link {
  color: var(--primary);
  cursor: pointer;
}
.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 40px;
  margin-bottom: 12px;
  grid-template-columns: 343px repeat(3, 277.5px);
  grid-gap: 12px;
  > * {
    max-height: 44px;
  }
}

.dtc-search1 {
  grid-template-columns: 343px max-content 340px repeat(30, max-content);
}
.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 110px;
  background: #0379fd;
  color: #fff;
}
.big-title {
  border-radius: 5px 5px 0 0;
  padding: 10px;
  margin-bottom: 0;
  background: #f7bb07;
  color: #e9e9e9;
  font-size: 22px;
  font-weight: 500;
  font-family: Microsoft JhengHei, Helvetica;
  border: 1px solid #dfa906;
  .icon {
    color: #e9e9e9;
  }
}
</style>
