import Vue from "vue";
import "./plugins/axios";
import "jschannel";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VCalendar from "v-calendar";
import DatePickerTW from "@/components/DatePickerTW.vue";
import DatePickerTWYear from "@/components/DatePickerTWYear.vue";

import $ from "jquery";
window.$ = $;
// import VueTabs from 'vue-nav-tabs'
// import 'vue-nav-tabs/themes/vue-tabs.css'
// Vue.use(VueTabs)
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// import contenteditable from "vue-contenteditable";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import moment from "moment";
import VueNumeric from "vue-numeric";
Vue.use(VueNumeric);
import VueYoutube from "vue-youtube";

Vue.use(VueYoutube);

// Vue.use(contenteditable);
Vue.use(VCalendar);
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.component("date-picker-tw", DatePickerTW);
Vue.component("date-picker-tw-year", DatePickerTWYear);

Vue.config.productionTip = false;
/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

Vue.prototype.$moment = moment;

Vue.prototype.$formatPrice = function(value) {
  value = Number(value);
  if (!value || isNaN(value) || value==0) return "0";
  //console.log('value',value)
  let val = (value / 1).toFixed(0).replace(".", ",");
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

Vue.prototype.$formatDate = function(str) {
  if (!str) return "";
  let date1 = moment(str).format();
  return date1.split("T")[0];
};

Vue.prototype.$dataFilter = function(key, qs, str) {
  str = "" + str;
  const lower = str.toLowerCase();
  return qs + `&$filter=substringof('${lower}', tolower(${key})) eq true`;
};

Vue.prototype.$appendFilter = function(key, qs, str) {
  str = "" + str;
  const lower = str.toLowerCase();
  return qs + ` and substringof('${lower}',tolower(${key})) eq true`;
};
Vue.prototype.$appendFilter2 = function(key, qs, str) {
  str = "" + str;
  const lower = str.toLowerCase();
  return qs + ` and substringof('${lower}','${key}') eq true`;
};

Vue.prototype.$equalFilter = function(key, qs, v) {
  return qs + `&$filter=${key} eq '${v}'`;
};

Vue.prototype.$appendEqualFilter = function(key, qs, v) {
  return qs + ` and ${key} eq '${v}'`;
};
Vue.prototype.$equalFilter2 = function(key, qs, v) {
  return qs + `&$filter=${key} eq ${v}`;
};

Vue.prototype.$appendEqualFilter2 = function(key, qs, v) {
  return qs + ` and ${key} eq ${v}`;
};

Vue.prototype.$timeFilter = function(key, qs, timeone, timetwo) {
  return (
    qs +
    `&$filter=${key} ge DateTime${timeone} and ${key} lt DateTime${timetwo}`
  );
};

Vue.prototype.$equalTimeFilter = function(key, qs, timeone, timetwo) {
  return (
    qs +
    `&$filter=${key} gt DateTime'${timeone}T00:00:00' and ${key} lt DateTime'${timetwo}T23:59:59'`
  );
};

Vue.prototype.$appendTimeFilter = function(key, qs, timeone, timetwo) {
  return (
    qs +
    ` and ${key} gt DateTime'${timeone}T00:00:00' and ${key} lt DateTime'${timetwo}T23:59:59'`
  );
};

Vue.prototype.$appendStartswithFilter = function(key, qs, v) {
  return qs + ` and startswith(${key}, '${v}')`;
};
Vue.prototype.$equalSubstringofFilter = function(key, qs, v) {
  return qs + `&$filter=substringof('${v}' , ${key} )`;
};
Vue.prototype.$appendSubstringofFilter = function(key, qs, v) {
  return qs + ` and substringof('${v}' , ${key} )`;
};

Vue.prototype.$twDate = function(str) {
  if (!str || str.startsWith("0")) return "";
  const twYear = +str.split("T")[0].split("-")[0] - 1911;
  const twYear2 = `0${twYear}`;
  const d = new Date(str.split("T")[0]);
  return `${+twYear > 99 ? twYear : twYear2}` + "-" + moment(d).format("MM-DD");
};

Vue.prototype.$twDate2 = function(str) {
  if (!str) return "";
  const twYear = +str.split("-")[0] - 1911;
  const twYear2 = `0${twYear}`;
  return (
    `${+twYear > 99 ? twYear : twYear2}` + "-" + moment(str).format("MM-DD")
  );
};
Vue.prototype.$twDate3 = function(str) {
  if (str == "Invalid date") {
    return "Invalid date";
  } else {
    const twYear = +str.split(".")[0] - 1911;
    const twYear2 = `0${twYear}`;
    return (
      `${+twYear > 99 ? twYear : twYear2}` + "-" + moment(str).format("MM-DD")
    );
  }
};
Vue.prototype.$twDate4 = function(str) {
  const twYear = +str.split("-")[0] - 1911;
  const twYear2 = `0${twYear}`;
  return (
    `民國${+twYear > 99 ? twYear : twYear2}` +
    "年" +
    moment(str).format("MM月DD日")
  );
};
Vue.prototype.$twDate5 = function(str) {
  if (!str) return "";
  const twYear = +str.split("-")[0] - 1911;
  const twYear2 = `0${twYear}`;
  const time = str.split("T")[1];
  return (
    `${+twYear > 99 ? twYear : twYear2}` +
    "-" +
    moment(str).format("MM-DD") +
    " " +
    time
  );
};

Vue.prototype.$usDate = function(str) {
  if (!str) return "";
  const usYear = +str.split("-")[0] + 1911;
  const usMonth = +str.split("-")[1];
  const usDay = +str.split("-")[2];
  if (+usMonth < 10) {
    return +usDay < 10
      ? `${usYear}-0${usMonth}-0${usDay}`
      : `${usYear}-0${usMonth}-${usDay}`;
  } else {
    return +usDay < 10
      ? `${usYear}-${usMonth}-0${usDay}`
      : `${usYear}-${usMonth}-${usDay}`;
  }
};

Vue.prototype.$dedup = function(arr) {
  let hashTable = {};
  return arr.filter(function(el) {
    var key = JSON.stringify(el);
    var match = Boolean(hashTable[key]);
    return match ? false : (hashTable[key] = true);
  });
};

Vue.directive("click-print", {
  bind(el, binding) {
    el.addEventListener("click", () => printCertainElement(binding.value.id));
  },
  unbind(el) {
    el.removeEventListener("click", printCertainElement);
  },
});

function printCertainElement(id) {
  const _el = document.getElementById(id);
  if (_el) {
    const _printArea = document.createElement("DIV");
    document.body.append(_printArea);
    _printArea.append(_el.cloneNode(true));
    document.querySelector(".dtx-main-scetion").classList.add("no-print");
    window.print();
    document.querySelector(".dtx-main-scetion").classList.remove("no-print");
    document.body.removeChild(_printArea);
  }
}

Vue.directive("click-outside", {
  bind() {
    this.event = (event) => this.vm.$emit(this.expression, event);
    this.el.addEventListener("click", this.stopProp);
    document.body.addEventListener("click", this.event);
  },
  unbind() {
    this.el.removeEventListener("click", this.stopProp);
    document.body.removeEventListener("click", this.event);
  },

  stopProp(event) {
    event.stopPropagation();
  },
});

let handleOutsideClick;
Vue.directive("closable", {
  bind(el, binding, vnode) {
    handleOutsideClick = (e) => {
      e.stopPropagation();
      const { handler, exclude } = binding.value;
      let clickedOnExcludedEl = false;
      exclude.forEach((refName) => {
        if (!clickedOnExcludedEl) {
          const excludedEl = vnode.context.$refs[refName];
          // clickedOnExcludedEl = excludedEl.contains(e.target);
        }
      });
      // if (!el.contains(e.target) && !clickedOnExcludedEl) {
      //   vnode.context[handler]();
      // }
    };
    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);
  },

  unbind() {
    document.removeEventListener("click", handleOutsideClick);
    document.removeEventListener("touchstart", handleOutsideClick);
  },
});

window.level4 = ["一", "二", "三", "四"];
window.level6 = ["一", "二", "三", "四", "五", "六"];
