<template>
  <section
    class="dtx-main-scetion"
    :style="
      $route.path.includes('home')
        ? 'background: #636363; box-shadow:0 100vh 0  0 #636363;'
        : ''
    "
  >
    <main
      class="warning-idle"
      v-if="isAppIdle && !$route.path.includes('login')"
    >
      <nav>
        <header>公費生養成計畫服務平臺</header>
        <p style="margin-top:35px;">系統已閒置一段期間,會將您自動登出帳戶</p>
        <p style="margin-top:12px;">
          將在
          <VueCountdown :time="countDownNum">
            <template slot-scope="props">{{
              +props.seconds ? props.seconds : "60"
            }}</template>
          </VueCountdown>
          秒鐘之後自動登出
        </p>
      </nav>
    </main>
    <StudentServiceInfo key="StudentServiceInfo"></StudentServiceInfo>
    <b-button
      variant="danger"
      @click="printCurrentPage"
      class="mr-2 print-dtc-page d-print-none"
      v-if="['doc'].some((k) => $route.path.includes(k)) && showButton"
    >
      <i class="fas fa-print mr-1" />列印
    </b-button>
    <header
      class="d-flex align-items-center all-header d-print-none"
      v-if="$route.meta.isShowHead"
    >
      <b-img
        :src="require('assets/images/logo.svg')"
        height="45px"
        style="margin-right:30px;margin-left: 5px;cursor: pointer;"
        @click="$router.push('home')"
      />
      <!--<span class="px-2">在地醫事人員招生、輔導、分發及服務平臺</span>-->
      <span class="px-2">公費醫事人員資訊管理平臺</span>

      <div class="main-name">
        {{ accountName }}
        <div class="main-message" @click="openStuInfo">
          <i class="fas fa-envelope"></i>
          <div class="main-count" v-show="!alreadyCheck">
            {{ totalInfoCount }}
          </div>
        </div>
      </div>
      <b-button
        class="main-button"
        variant="light"
        @click="$router.push('home').catch(() => {})"
      >
        <i class="fas fa-bookmark"></i>
        主選單
      </b-button>
      <b-button class="logout-button" variant="light" @click="logout">
        <i class="fas fa-sign-out-alt"></i>

        登出
      </b-button>
    </header>
    <template v-if="$route.path.includes('home')">
      <router-view />
    </template>
    <template v-else-if="$route.path.includes('login')">
      <router-view />
    </template>
    <template v-else-if="$route.name == 'registercondition'">
      <router-view />
    </template>
    <template v-else-if="$route.path.includes('dtcvuetabs')">
      <div class="dtc-edit-dtcvuetabs-mode">
        <router-view> </router-view>
      </div>
    </template>
    <template v-else>
      <div id="app" class="dtc-edit-mode">
        <div
          class="dtx-server-error d-print-none"
          v-if="errors"
          @click="errors = false"
        >
          <div>{{ this.errors }}</div>
        </div>

        <router-view> </router-view>
      </div>
    </template>

    <b-overlay
      :show="storeIsLoading"
      v-if="storeIsLoading"
      variant="dark"
      class="position-fixed text-white"
      style="width: 100vw; height: 100vh;"
    />
  </section>
</template>
<script>
import NavPrintPage from "@/components/NavPrintPage.vue";
import Home from "@/components/land/Home.vue";
import StudentServiceInfo from "@/components/StudentServiceInfo.vue";
import html2canvas from "html2canvas";
import { store, actions } from "@/store/global.js";
import IdleJs from "idle-js";
import VueCountdown from "@chenfengyuan/vue-countdown";

export default {
  name: "tdd",
  data() {
    return {
      compontKey: 0,
      showButton: false,
      isSaveOk: false,
      errors: false,
      debug: window.dtcDebug,
      notHome: true,
      hrefContainsNoHome: location.href.includes("home") ? false : true,

      idle: "",
      finalIdle: "",

      showIdleTimerMsgAt: 1000 * 60 * 9,
      finalIdleAt: 1000 * 60 * 10,
      countDownNum: 1000 * 60 * 1,

      // fortest  showIdleTimerMsgAt+countDownNum==finalIdleAt
      // showIdleTimerMsgAt: 1000 * 20 * 1,
      // finalIdleAt: 1000 * 35 * 1,
      // countDownNum: 1000 * 15 * 1,
    };
  },
  computed: {
    storeIsLoading() {
      return store.isLoading;
    },
    accountName() {
      return store.accountName;
    },
    totalInfoCount() {
      return store.totalAllInfoCount;
    },
    alreadyCheck() {
      return store.isAlreadyCheckInfo;
    },
    isAppIdle() {
      return store.isIdle;
    },
    isLogin() {
      return !!store.isLogin;
    },
  },
  components: { NavPrintPage, Home, StudentServiceInfo, VueCountdown },

  methods: {
    async openStuInfo() {
      this.compontKey += 1;
      store.isAlreadyCheckInfo = true;
      store.totalAllInfoCount = 0;
      this.$bvModal.show("studentServiceInfoMessage");
    },
    logout() {
      actions.logout();
      this.$router.push("/login");
    },
    printDtxPage() {
      window.print();
    },
    printCurrentPage() {
      const num = localStorage.dtcPage4Print;
      const msg = "dtc-print-self-" + num;
      document.documentElement.scrollTop = 0;
      const el = document.querySelector("#dtc-print-it");
      const print = document.querySelectorAll(".dtc-print-btn2");
      const appEl = document.querySelector("#app");
      appEl.classList.remove("dtc-edit-mode");
      appEl.classList.add("dtc-print-mode");
      if (print) {
        print.forEach((el) => (el.style.display = "none"));
      }
      const removes = document.querySelectorAll(".dtc-remove");
      if (removes) {
        removes.forEach((el) => (el.style.display = "none"));
      }
      if (!el) {
        window.print();
      } else {
        let scale = 2;
        if (this.$route.path.includes("doc1")) {
          scale = 0.9;
        } else if (
          this.$route.path.includes("doc6") ||
          this.$route.path.includes("doc4")
        ) {
          scale = 0.96;
        } else if (
          this.$route.path.includes("doc8-1") ||
          this.$route.path.includes("doc8-3")
        ) {
          scale = 0.8;
        }
        html2canvas(el, { backgroundColor: "#FFF", scale }).then((canvas) => {
          const src = canvas.toDataURL();
          this.printImage(src);
        });
      }
    },
    printImage(src) {
      const appEl = document.querySelector("#app");
      appEl.classList.add("dtc-edit-mode");
      appEl.classList.remove("dtc-print-mode");
      const print = document.querySelectorAll(".dtc-print-btn2");
      if (print) {
        print.forEach((el) => (el.style.display = "inline-block"));
      }
      const removes = document.querySelectorAll(".dtc-remove");
      if (removes) {
        removes.forEach((el) => (el.style.display = "block"));
      }
      var win = window.open("about:blank", "_new");
      win.document.open();
      win.document.write(
        [
          "<html>",
          "   <head>",
          "   </head>",
          "   <style>@page { size: A4 landscape }</style>",
          '   <body onload="window.print()" onafterprint="window.close()">',
          '       <img style="width: 100%;" src="' + src + '"/>',
          "   </body>",
          "</html>",
        ].join("")
      );
      win.document.close();
      this.stopPrint = false;
    },
    // for auto logout
    restartIdlesDetection() {
      if (this.$route.path.includes("login")) {
        this.idle.stop();
        this.finalIdle.stop();
        mutations.logout();
        return;
      }
      this.idle
        .stop()
        .reset()
        .start();

      this.finalIdle
        .stop()
        .reset()
        .start();
    },
    detectIdles() {
      const vm = this;
      this.idle = new IdleJs({
        idle: vm.showIdleTimerMsgAt,
        onIdle: function() {
          store.isIdle = true;
        },
        onActive: function() {
          store.isIdle = false;
        },
      });
      this.finalIdle = new IdleJs({
        idle: vm.finalIdleAt,
        onIdle: async function() {
          store.isLogin = false;
          store.isIdle = false;
          actions.logout();
          vm.$router.push("/login");
        },
      });
      this.idle.start();
      this.finalIdle.start();
    },
  },
  async created() {
    if (sessionStorage.token) {
      this.detectIdles();
      if (!Boolean(this.accountName)) {
        store.accountName = Boolean(sessionStorage.fullname)
          ? `${window.sessionStorage.fullname}`.replace(/\"/g, "")
          : "";
      }
    }
    if (!localStorage.dtcCountry) {
      // const map = await window.axios.get("SysCode/Get?category=country");
      // const arr = [];
      // map.forEach((s) => arr.push({ value: s.Code, text: s.Display }));
      localStorage.dtcCountry = JSON.stringify([]);
    }
    if (!localStorage.dtcSchoolList) return;
    window.dtcSchools = JSON.parse(localStorage.dtcSchoolList);
  },
  beforeCreate() {
    let year = new Date()
      .toISOString()
      .split("T")[0]
      .split("-")[0];
    year = Number(year);
    const arr = [];
    for (let i = 0; i < 7; ++i) {
      arr.push(year - 1911);
      --year;
    }
    window.twYears = arr;
    window.dtcDebug =
      location.href.includes("192") || location.href.includes("localhost")
        ? true
        : false;
  },
  async mounted() {
    setTimeout(() => {
      this.showButton = true;
    }, 100);

    // location.href.includes("home")
    //   ? (this.notHome = false)
    //   : (this.notHome = true);
    // try {
    //   window.dtxChan = Channel.build({
    //     window: window.parent,
    //     origin: "*",
    //     scope: "testScope",
    //     debugOutput: true,
    //   });
    //   //alert(window.dtxChan);
    // } catch (e) {
    //   alert("not in iframe");
    // }
    this.$root.$on("dtc-save-form-ok", () => {
      this.isSaveOk = true;
      setTimeout(() => (this.isSaveOk = false), 2500);
    });

    this.$root.$on("dtc-server-error", (e) => {
      this.errors = e;
      setTimeout(() => (this.errors = false), 1000 * 10);
    });
  },
  watch: {
    // $route(to, from) {
    //   window.scrollTo({
    //     top: 0,
    //     left: 0,
    //     behavior: "smooth",
    //   });
    //   to.path.includes("login") ? (store.isIdle = false) : "";
    // },
    isLogin: {
      handler: function(v) {
        if (v) {
          this.detectIdles();
        } else {
          this.idle.stop();
          this.finalIdle.stop();
        }
      },
      deep: true,
      // immediate: true,
    },
  },
};
</script>

<style lang="scss">
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootstrap-vue/src/index.scss";

.warning-idle {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(#000, 0.8);
  display: grid;
  place-items: center;
  z-index: 99999;
  > nav {
    width: 500px;
    height: 200px;
    border-radius: 5px;
    color: black;
    background: var(--light);
    display: flex;
    flex-direction: column;
    text-align: center;
    header {
      height: 40px;
      color: white;
      background: var(--danger);
      font-size: 20px;
      padding-left: 5px;
      line-height: 40px;
    }
  }
}

.print-dtc-page {
  position: fixed;
  top: 86px;
  right: 100px;
  // z-index: 99999;
  z-index: 999;
}
.dtx-main-scetion {
  --landing-width: 1554px;
  // display: grid;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  // width: 100vw;
  min-height: 100vh;
}
#app {
  font-family: "Microsoft JhengHei", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  position: relative;
  color: #2c3e50;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  // font-weight: bold !important;
  font-size: 16px !important;
  input {
    border-radius: none !important;
  }
}

.dtc-save-print-btn {
  position: fixed;
  top: 10px;
  left: 20px;
}

.dtc-print-btn,
.dtx-red {
  position: absolute;
  top: 0px;
  left: 12px;
  // z-index: 99999;
  z-index: 999;
  background: white;
}

.dtx-red {
  position: fixed;
  top: 10px;
  left: auto;
  right: 200px;
  color: red;
  font-size: 16px;
  padding: 6px;
  border-radius: 5px;
}

@media only screen2 {
  #app {
    color: var(--light);
    background: var(--dark);
  }
  input {
    background: var(--dark);
    color: var(--light);
  }
  .input-group-prepend input:-webkit-autofill,
  input:-webkit-autofill {
    --webkit-box-shadow: 0 0 0px 1000px #000 inset;
    background: transparent;
  }
  .input-group-prepend input:-webkit-autofill:first-line,
  input:-webkit-autofill:first-line {
    color: var(--light);
  }
}
// input[type="number"]::-webkit-inner-spin-button,
// input[type="number"]::-webkit-outer-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }
*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

.dtx-save-ok {
  position: fixed;
  top: 80px;
  color: var(--success);
  font-size: 24px;
  writing-mode: vertical-rl;
  width: 300px;
  height: 300px;
  right: 40px;
}

#app .dtc-fill-required::after {
  font-size: 24px !important;
}
</style>

<style>
#app .btn-secondary {
  border-color: none !important;
  border: none !important;
  background: white !important;
  color: black !important;
  position: relative;
}
.dtc-edit-mode [contenteditable] {
  /* position: relative; */
  outline-offset: -3px;
}
.dtc-edit-mode [contenteditable][placeholder]:empty:before {
  content: "請填寫";
  position: absolute;
  width: 100%;
  height: 99%;
  text-align: center;
  display: block;
  font-size: 12px;
  color: var(--gray);
  background-color: transparent;
}
.dtc-edit-mode {
  position: absolute;
  top: 70px;
  left: 5px;
}
.dtc-edit-dtcvuetabs-mode {
  padding-top: 70px !important;
  width: 98vw;
  overflow-x: hidden;
  /* margin-left: 5px !important; */
}
</style>

<style lang="scss">
.dtx-tooltip {
  position: relative;
  .dtx-tip {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    color: var(--gray);
    background-color: transparent;
    border-right: none !important;
    padding-top: 2px;
    z-index: 4;
  }
}

.dtc-print-mode {
  top: 90px;
  .dtx-tip {
    display: none !important;
  }
}
@media print {
  .dtc-print-mode {
    top: 0px;
  }
}
.dtx-server-error {
  height: 56px;
  width: 100vw;
  text-align: center;
  background: var(--danger);
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: grid;
  place-items: center;
  &::after {
    position: absolute;
    top: 12px;
    right: 24px;
    color: white;
    font-size: 24px;
    content: "X";
    display: block;
    width: 30px;
    height: 30px;
  }
}
.all-header {
  background-color: #7e2ca3;
  color: #fff;
  height: 65px;
  margin: 0px;
  padding: 10px 0px 10px 20px;
  color: #ffffff;
  font-size: 30px;
  font-weight: bold;
  font-family: 微軟正黑體;
  line-height: 45px;
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 99;

  .main-name {
    position: absolute;

    right: 285px;
    top: 9px;
    font-size: 16px;
    .main-message {
      position: absolute;
      left: -39px;
      top: 0px;
      font-size: 26px;
      color: #f3c963;
      cursor: pointer;
      .main-count {
        position: absolute;
        top: 0;
        right: -12px;
        width: 20px;
        height: 20px;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #98142b;
        color: #fff;
      }
    }
  }
  .main-button {
    position: absolute;
    right: 177px;
    top: 14px;
    font-weight: bolder;
  }
  .logout-button {
    position: absolute;
    right: 84px;
    top: 14px;
    font-weight: bolder;
  }
  // font-weight: bold !important;
}
</style>
<style>
#app .btn-secondary {
  font-size: 11px !important;
  padding-left: 0px;
  transform: scale(0.91);
}

main .custom-select {
  /* border: none !important; */
  font-size: 0.78rem !important;
  padding-left: 0px;
  padding: 0px;
  width: 98%;
  margin: 0px;
  font-weight: bold;
}
select {
  text-align: center;
  text-align-last: center;
}
.dtx-xxx-small-text {
  font-size: 0.72rem !important;
  padding: 0px;
  margin: 0px;
  margin-top: -3px;
}
.avoid-clicks {
  pointer-events: none;
}
</style>
<style scoped lang="scss">
::v-deep.btn-light {
  background: #c8c2c23b;
  border-color: #c1c1c1;
  color: #fff;
  border-radius: 4px;
}
</style>
