<template>
  <section class="dtc-main-section">
    <div
      class="dtc-print"
      v-for="(topItem, topIndex) in dtcGridSize"
      :key="topIndex"
      style="page-break-after:always;"
      :sty2le="topIndex > 1 ? 'page-break-after:always;' : ''"
    >
      <section class="dtc-print7-grid-header">
        <span class="dtc-title" v-if="topIndex == 0">學校 學年度第 學期「原住民及離島地區醫事人員養成計畫」公費生待遇補助 經費總表</span>
        <!-- <span class="dtc-title" v-if="topIndex != 0">學校 學年度第 學期「原住民及離島地區醫事人員養成計畫」公費生待遇補助 經費總表</span> -->
        <div>學系</div>
        <div>年級</div>
        <div>人數</div>
        <section class="sec1">
          <header>
            註冊費
            <small style="font-size : 9px; -webkit-transform : scale(0.75);">註1</small>
          </header>
          <div>學雜費</div>
          <div>學生平安保險費</div>
          <div>
            其他相關費用(如語言實習或電腦使用費等，
            <span style="color:red;">請增列或備註說明</span>)
          </div>
          <footer class="pt-3 pl-3">依各校及各系所就學所需支付之註冊費用核實支付。</footer>
        </section>
        <section class="sec2">
          <header>
            生活費
            <small style="font-size : 9px; -webkit-transform : scale(0.75);">註2</small>
          </header>
          <div>膳食費</div>
          <div>零用津貼</div>
          <div>課業費</div>
          <div>書籍費</div>
          <div>制服費</div>
          <div>應屆畢業生旅行參訪費註4</div>
          <footer>
            <div>每名每學期3,250元/月/*6個月=19,500元</div>
            <div>每名每學期3,500元*6個月=21,000元</div>
            <div>每名每學期1,000元</div>
            <div>每名每學期4,000元</div>
            <div>每名每學期2,500元</div>
            <div>每名畢業生3,000元</div>
          </footer>
        </section>
        <section class="sec3">
          <header>
            旅宿費
            <small style="font-size : 9px; -webkit-transform : scale(0.75);">註3</small>
          </header>

          <div>
            返鄉旅費
            <small style="font-size : 9px; -webkit-transform : scale(0.75);">註5</small>
          </div>
          <div>
            住宿費
            <small style="font-size : 9px; -webkit-transform : scale(0.75);">註6</small>
          </div>
          <footer>
            <div>可依第4期計畫每名5,000元或實際狀況預估</div>
            <div>每名每學期3,000元/月/*6個月=18,000元</div>
          </footer>
        </section>
        <div style="border-right:0">小計</div>
      </section>
      <main
        v-for="(item,idx) in pageRows.slice(topIndex * pageSize, pageSize + topIndex * pageSize)"
        :key="item.myKey"
        class="dtc-table"
      >
        <div>
          <input class="input1 d-print-none" type="number" v-model="item.where" />
          <span
            style="border:none !important;font-size:16px; !important;padding:3px"
          >{{ item.where }}</span>
        </div>
        <div>
          <input
            class="input1 d-print-none"
            type="number"
            v-model="item.year"
            :disabled="isNaN(item.where)"
          />
          <span
            style="border:none !important;font-size:16px; !important;padding:3px"
          >{{ item.year }}</span>
        </div>
        <div>
          <input
            class="input1 d-print-none"
            type="number"
            v-model="item.persons"
            :disabled="isNaN(item.year)"
            @change="rowPersonUpdate(item)"
          />
          <span
            style="border:none !important;font-size:16px; !important;padding:3px"
          >{{ item.persons }}</span>
        </div>
        <section class="dtc-s1" style="border:none;">
          <div>
            <input
              @change="rowPersonUpdate(item)"
              :disabled="isNaN(item.persons)"
              class="input2 d-print-none"
              type="number"
              v-model="item.learnSpend$"
            />
            <span
              style="border:none !important;font-size:16px; !important;padding:3px"
            >{{ item.learnSpend$ }}</span>
          </div>
          <div>
            <input
              @change="rowPersonUpdate(item)"
              :disabled="isNaN(item.persons)"
              class="input2 d-print-none"
              type="number"
              v-model="item.insure$"
            />
            <span
              style="border:none !important;font-size:16px; !important;padding:3px"
            >{{ item.insure$ }}</span>
          </div>
          <div>
            <input
              @change="rowPersonUpdate(item)"
              :disabled="isNaN(item.persons)"
              class="input2 d-print-none"
              type="number"
              v-model="item.others$"
            />
            <span
              style="border:none !important;font-size:16px; !important;padding:3px"
            >{{ item.others$}}</span>
          </div>
        </section>
        <section class="dtc-s2" style="border:none;">
          <div v-html="item.eat$"></div>
          <div v-html="item.allow$"></div>
          <div v-html="item.class$"></div>
          <div v-html="item.book$"></div>
          <div v-html="item.cloth$"></div>
          <div v-html="item.travel$"></div>
        </section>
        <section class="dtc-s3" style="border:none;">
          <div v-html="item.backHome$"></div>
          <div v-html="item.live$"></div>
        </section>
        <div
          style="border:none; text-align:right;padding-top:5px;padding-right:2px;"
          v-html="item.rowTotal"
        ></div>
        <span
          style="border:none;"
          class="dtc-page d-none d-print-block"
          v-if="idx + 1 == pageSize && topIndex + 1 != dtcGridSize"
        >{{ `第${topItem}/${dtcGridSize}頁` }}</span>
      </main>
      <footer class="dtc-footer-total" v-if="topItem === dtcGridSize">
        <div class="dtx-count">小計</div>
        <section class="dtc-s1" style="border:none;">
          <div v-html="learnSpendFooter" style="padding-top:5px;"></div>
          <div v-html="insureFooter" style="padding-top:5px;"></div>
          <div v-html="othersFooter" style="padding-top:5px;"></div>
        </section>
        <section class="dtc-s2" style="border:none;">
          <div v-html="eatFooter"></div>
          <div v-html="allowFooter"></div>
          <div v-html="classFooter"></div>
          <div v-html="bookFooter"></div>
          <div v-html="clothFooter"></div>
          <div v-html="travelFooter"></div>
        </section>
        <section class="dtc-s3" style="border:none;">
          <div v-html="backHomeFooter"></div>
          <div v-html="liveFooter"></div>
        </section>
        <div
          style="border:none;padding-top:5px;text-align:right;padding-right:3px;"
          v-html="sumFooter"
        ></div>
        <div
          style="grid-column: 1/ -1;;border:none;border-top:1px solid var(--dtc-border-color);page-break-after:always;"
        ></div>
        <div
          style="grid-column: 1/ -1;border:none;border-top:1px solid var(--dtc-border-color);padding-top:5px; min-height:180px"
        >總計</div>
        <div
          style="grid-column: 1/ -1;border:none;border-top:1px solid var(--dtc-border-color)"
          class="dtc-sign-area"
        >
          <div>承辦單位</div>
          <div>註冊組(或學籍稽核單位)</div>
          <div>出納組</div>
          <div>會計(財務)</div>
          <div style="border-right:none;">校長(或授權人)</div>
        </div>
        <div
          style="grid-column: 1/ -1;border:none;border-top:1px solid var(--dtc-border-color); min-height:110px !important"
          class="dtc-sign-area"
        ></div>

        <div
          style="grid-column: 1/ -1;border:none;border-top:1px solid var(--dtc-border-color);"
          class="dtc-note-area"
        >
          <div>【備註】</div>
          <div>註1:註冊費：依各校系所就學之註冊費用編列，並以其註冊費用核實支付。</div>
          <div>註2:生活費：依行政院核定金額編列，每名每項補助費不得超過該定額之補助標準。</div>
          <div>註3: 旅宿費：由公費生檢具單據或證明後核給。</div>
          <div>註4: 應屆畢業生旅行參訪費：須於公費生畢業當年度報支。</div>
          <div>註5:返鄉旅費：應以大眾交通工具並按返鄉必經之順路計算(如住家與學校未超過30公里則不能支領交通費，超過30公里者每學期限補助一次) 。</div>
          <div>註6:住宿費：每人每月3,000元，須檢附租賃契約書影本，如為合租無法以學生本人簽訂者，應請學生附切結書與繳費證明供核。</div>
        </div>

        <b-button
          @click="++dtcGridSize"
          class="d-print-none dtc-add"
          variant="primary"
          size="sm"
          style="border-right:none;"
        >增加總表</b-button>
      </footer>

      <!-- <footer class="dtc-signup" v-if="topItem === dtcGridSize">
        <div>總計</div>
      </footer>-->
    </div>
  </section>
</template>

<script>
const rowObj = {};
let pageRows = new Array(1600).fill().map((s, t) => {
  return { myKey: t };
});

export default {
  name: "totalSpend",
  data() {
    return {
      dtcGridSize: 1,
      pageRows,
      learnSpendFooter: 0,
      insureFooter: 0,
      othersFooter: 0,
      eatFooter: 0,
      allowFooter: 0,
      classFooter: 0,
      bookFooter: 0,
      clothFooter: 0,
      travelFooter: 0,
      backHomeFooter: 0,
      liveFooter: 0,
      sumFooter: 0,
      pageSize: 10
    };
  },

  methods: {
    addLearnSpent(item) {
      this.rowPersonUpdate(item);
    },
    rowPersonUpdate(item) {
      if (isNaN(item.persons) || item.persons < 0) return;
      const n = item.persons;
      item.eat$ = 19500 * n;
      item.allow$ = 21000 * n;
      item.class$ = 1000 * n;
      item.book$ = 4000 * n;
      item.cloth$ = 2500 * n;
      item.travel$ = 3000 * n;
      item.backHome$ = 5000 * n;
      item.live$ = 18000 * n;
      /////////////
      item.rowTotal = 0;
      Object.keys(item).forEach(s => {
        if (s.includes("$") && !isNaN(item[s])) {
          item.rowTotal += Number(item[s]);
        }
      });
      this.learnSpendFooter = this.insureFooter = this.othersFooter = 0;
      this.travelFooter = this.clothFooter = this.bookFooter = this.classFooter = this.allowFooter = this.eatFooter = 0;
      this.backHomeFooter = this.liveFooter = 0;
      this.sumFooter = 0;
      this.pageRows.forEach(s => {
        //////////////////////////////////////////////////
        this.learnSpendFooter +=
          Number(s.learnSpend$) > 0 ? Number(s.learnSpend$) : 0;
        this.insureFooter += Number(s.insure$) > 0 ? Number(s.insure$) : 0;
        this.othersFooter += Number(s.others$) > 0 ? Number(s.others$) : 0;
        ////////
        this.eatFooter += s.eat$ > 0 ? s.eat$ : 0;
        this.allowFooter += s.allow$ > 0 ? s.allow$ : 0;
        this.classFooter += s.class$ > 0 ? s.class$ : 0;
        this.bookFooter += s.book$ > 0 ? s.book$ : 0;
        this.clothFooter += s.cloth$ > 0 ? s.cloth$ : 0;
        this.travelFooter += s.travel$ > 0 ? s.travel$ : 0;
        /////
        this.backHomeFooter += s.backHome$ > 0 ? s.backHome$ : 0;
        this.liveFooter += s.live$ > 0 ? s.live$ : 0;
        //
        this.sumFooter += s.rowTotal > 0 ? s.rowTotal : 0;
      });
    }
  },
  mounted() {
    // alert(this.pageRows.length);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-print {
  position: relative;
  --dtc-border-color: var(--dark);
  margin-top: 30px;
  margin: 30px auto 2px auto;
  width: 1122px;
  margin-bottom: 60px;
  .input1 {
    border: none;
    max-width: 49px;
    padding: 3px;
  }
  .input2 {
    border: none;
    max-width: 89px !important;
    height: 30px;
    display: inline-block;
    padding: 3px;
  }
}

.dtc-page {
  position: absolute;
  bottom: 0;
  right: 0px;
  transform: translateY(65px);
  border: none;
}
.dtc-add {
  position: absolute;
  bottom: 0;
  right: 0px;
  transform: translateX(80px);
  border: none;
}
.dtc-title {
  position: absolute;
  top: 0px;
  left: 50%;
  display: block;
  width: 650px;
  transform: translate(-50%, -30px);
  font-size: 16px;
  font-weight: bold;
}

.dtc-print7-grid-header {
  position: relative;
  z-index: 13px;
  font-size: 12px !important;
  display: grid;
  padding: 0px;
  border: 1px solid var(--dtc-border-color);
  width: max-content;
  grid-template-columns: repeat(3, 50px) 290px 350px 250px 80px;
  > div {
    height: 300px;
    border-right: 1px solid var(--dtc-border-color);
    line-height: 300px;
    text-align: center;
  }
  .sec1,
  .sec2,
  .sec3 {
    z-index: 2px;
    display: grid;
    height: 300px;
    grid-template-columns: repeat(3, 1fr);
    header {
      grid-column: 1 / -1;
      border-bottom: 1px solid var(--dtc-border-color);
      border-right: 1px solid var(--dtc-border-color);
      text-align: center;
      height: 30px;
    }
    > div {
      border-bottom: 1px solid var(--dtc-border-color);
      border-right: 1px solid var(--dtc-border-color);
      padding: 3px;
      height: 140px;
    }
    footer {
      grid-column: 1 / -1;
      height: calc(300px - 170px);
      border-right: 1px solid var(--dtc-border-color);
    }
  }
  .sec2 {
    grid-template-columns: repeat(6, 1fr);
    footer {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      border: none;
      > div {
        padding: 3px;
        border-right: 1px solid var(--dtc-border-color);
        //line-height: calc(300px - 170px);
      }
    }
  }

  .sec3 {
    grid-template-columns: repeat(2, 1fr);
    footer {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      border: none;
      > div {
        padding: 3px;
        border-right: 1px solid var(--dtc-border-color);
        //line-height: calc(300px - 170px);
      }
    }
  }
}
.dtc-table,
.dtc-footer-total {
  text-align: center !important;
  position: relative;
  border-left: 1px solid var(--dtc-border-color);
  border-bottom: 1px solid var(--dtc-border-color);
  border-right: 1px solid var(--dtc-border-color);
  font-size: 12px !important;
  display: grid;
  width: 1122px;
  grid-template-columns: repeat(3, 50px) 290px 350px 250px 80px;
  > * {
    min-height: 30px !important;
    max-height: 30px !important;
    border-right: 1px solid var(--dtc-border-color);
    > * {
      border-right: 1px solid var(--dtc-border-color);
      min-height: 30px !important;
      max-height: 30px !important;
    }
  }
}

.dtc-footer-total {
  grid-template-columns: 150px 290px 350px 250px 80px;
  .dtx-count {
    text-align: center;
    line-height: 30px;
    padding-top: 0px;
  }
}

.dtc-s1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.dtc-s2 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  > div {
    padding-top: 5px;
  }
}

.dtc-s3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  > div {
    padding-top: 5px;
  }
}

.dtc-sign-area {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 38px 60px;
  > div {
    padding-top: 5px;
  }
}
.dtc-note-area {
  min-height: 210px !important;
  text-align: left;
  > div {
    border-right: none;
    padding-left: 4px;
  }
}
@media screen {
  .dtc-main-section {
    padding-top: 12px;
  }
}

@media print {
  @page {
    size: A4 landscape;
  }
  .dtc-print {
    --dtc-border-color: var(--dark);
  }
}
</style>
