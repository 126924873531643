<template>
  <b-modal @shown="updateItem" title="密碼修改" id="changePassword" size="lg">
    <b-container fluid>
      <header class="my-dark">
        <b-input-group
          prepend="身分證字號"
          class="mb-2 mr-sm-2 mb-sm-0 row-content"
        >
          <b-form-input
            :value="accountIndentifier"
            placeholder="身分證字號"
            readonly
          ></b-form-input>
        </b-input-group>
        <b-input-group
          prepend="姓名"
          class="mb-2 mr-sm-2 mb-sm-0 row-content mt-2"
        >
          <b-form-input
            :value="accountName"
            placeholder="姓名"
            readonly
          ></b-form-input>
        </b-input-group>
        <b-input-group
          prepend="原始密碼"
          class="mb-2 mr-sm-2 mb-sm-0 row-content mt-2"
        >
          <b-form-input
            placeholder="輸入原始密碼"
            v-model="oldPwd"
          ></b-form-input>
        </b-input-group>
        <b-input-group
          prepend="新密碼"
          class="mb-2 mr-sm-2 mb-sm-0 row-content mt-2"
        >
          <b-form-input
            placeholder="輸入新密碼"
            v-model="newPwd"
          ></b-form-input>
        </b-input-group>
        <b-input-group
          prepend="確認新密碼"
          class="mb-2 mr-sm-2 mb-sm-0 row-content mt-2"
        >
          <b-form-input
            placeholder="確認新密碼"
            v-model="confirmPwd"
          ></b-form-input>
        </b-input-group>
        <div style="color:red" class="pt-2 mt-2">
          <span><i class="fas fa-exclamation-circle"></i></span
          ><span>
            密碼長度不可少於8字元且須包含數字、英文字母與特殊字元之組合</span
          >
        </div>
      </header>
    </b-container>

    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="danger"
          class="float-right  mr-3"
          @click="$bvModal.hide('changePassword')"
          >取消</b-button
        >
        <b-button
          variant="success"
          class="float-right ml-3 mr-2"
          @click="resetPwd"
          >確定</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import { store } from "@/store/global.js";
import buildQuery from "odata-query";
const rows = [10, 20, 50];
export default {
  name: "changePassword",
  data() {
    return {
      items: [],
      keys: [],
      oldPwd: "",
      newPwd: "",
      confirmPwd: "",
    };
  },
  computed: {
    accountName() {
      return Boolean(sessionStorage.fullname)
        ? `${window.sessionStorage.fullname}`.replace(/\"/g, "")
        : "";
    },
    accountIndentifier() {
      return Boolean(sessionStorage.igfjh)
        ? `${window.sessionStorage.igfjh}`.replace(/\"/g, "")
        : "";
    },
  },
  methods: {
    updateItem() {
      //   this.getData();
    },
    async resetPwd() {
      let check = true;
      let remindWod = "";
      let remindWod2 = "";
      const matches = this.newPwd.match(/\d+/g);
      if (matches == null) {
        check = false;
        remindWod = `${remindWod}數字`;
      }
      const matches2 = this.newPwd.match(/[A-Za-z]+/g);
      if (matches2 == null) {
        check = false;
        remindWod = `${remindWod}英文`;
      }
      const matches3 = this.newPwd.match(
        /[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]+/g
      );
      if (matches3 == null) {
        check = false;
        remindWod = `${remindWod}特殊字元`;
      }

      if (this.newPwd.length < 8) {
        check = false;
        remindWod2 = `密碼不可少於8字元`;
      }
      if (!check) {
        this.$bvToast.toast(
          `密碼不符合規定，須包含${remindWod}${remindWod2}，請重新輸入`,
          {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "danger",
          }
        );
        return;
      }
      if (this.newPwd != this.confirmPwd) {
        this.$bvToast.toast(`兩次密碼輸入不同，請重新輸入`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return;
      }

      const url = `auth/ChangeDrowssap`;
      const obj = {
        drowssap: this.oldPwd,
        newDrowssap: this.newPwd,
      };
      try {
        await window.axios.put(url, obj);
        this.$bvToast.toast(`變更密碼成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.$bvModal.hide("changePassword");
      } catch (e) {
        this.$bvToast.toast(`${e.response ? e.response.data : e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
  },

  mounted() {},
  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    // word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    word-break: keep-all;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-top: 1px solid #dee2e5;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
  }
  > div:first-child {
    border-left: 1px solid #dee2e5;
  }
  > div:last-child {
    // border-right: none;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 0;
    line-height: 46px;
    height: 46px;
  }
  .fee {
    text-align: right;
    padding-right: 10px;
  }
}
p {
  font-size: 2em;
  text-align: center;
}
.input-group-text {
  width: 100px;
  background: #0379fd;
  color: #fff;
}

div.input-group.special-col > div.input-group-prepend > div.input-group-text {
  width: 130px;
}
div.input-group.dtc-bg > div.input-group-prepend > div.input-group-text {
  background: #646969;
  color: #ffffff;
}

.input-group {
  margin-bottom: 10px;
}

::v-deep #changePassword > .modal-dialog > .modal-content > .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}

hr.new {
  border-top: 1px dashed #cacaca;
}
h4.title {
  font-size: 20px;
  font-weight: 700;
}
.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}
</style>
