<template>
  <section class="dtc-main-section">
    <h4 class="big-title">
      <span class="icon"><i class="fas fa-graduation-cap"></i></span>
      服務細項
    </h4>
    <header class="dtc-grid-header my-dark">
      <div v-for="(item, i) in headers" :key="`headers${i}`" :title="item">
        {{ item.name || item }}
      </div>
    </header>
    <main
      v-if="!items.length"
      class="mt-2"
      style="grid-column: 1/-1;font-size:26px;text-align:center;font-weight:600"
    >
      暫無資料
    </main>
    <!-- <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#EDEDED ;' : 'background-color: #DDDDDD;'
      "
    >
      <div>{{ item.YearMonth || "暫無資料" }}</div>
      <div>{{ item.Name || "暫無資料" }}</div>
      <div>{{ item.CourseName || "暫無資料" }}</div>
      <div>{{ item.MainHospital || "暫無資料" }}</div>
      <div>{{ item.Cooperation || "暫無資料" }}</div>
      <div>{{ item.Result || "暫無資料" }}</div>
    </main> -->
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#EDEDED ;' : 'background-color: #DDDDDD;'
      "
    >
      <div>{{ item.Hospital || "暫無資料" }}</div>
      <div>{{ item.JobTitle || "暫無資料" }}</div>
      <div>{{ item.StartDate ? $twDate2(item.StartDate):"暫無資料" }}</div>
      <div>{{ item.ReturnDate || "暫無資料" }}</div>
      <div>{{ item.Remark || "暫無資料" }}</div>
    </main>
  </section>
</template>

<script>
import queryString from "query-string";
import buildQuery from "odata-query";
import { store } from "@/store/global.js";
import moment from "moment";

// const headers = [
//   "日期",
//   "姓名",
//   "課程名稱",
//   "主要醫院",
//   "合作醫院",
//   "課程結果(評估日)",
// ];
const headers = ["服務單位", "職務", "執業日期", "歇業日期", "備註"];
const zero = "T00:00:00";
const rows = [10, 20, 50];
const year = new Date().getFullYear() - 1911;
let years = new Array(9).fill().map((s, i) => year + 1 - i);
years.unshift({ value: null, text: "全部" });

export default {
  name: "downarea",
  data() {
    return {
      headers,
      items: [],
      keys: [],
    };
  },
  components: {},
  computed: {},

  methods: {
    async getData() {
      const id = store.studentProgressInfo.id;
      // const url = `StudentInfo/GetTrainingHospital?identifier=${id}&category=E`;
      const url = `StudentInfo/GetServiceHistory?identifier=${id}&category=E`;

      try {
        const res = await window.axios.get(url);
        this.items = res ? [...res] : [];
      } catch (e) {
        console.log(e);
        // this.$bvToast.toast(e + ``, {
        //   title: "系統資訊",
        //   autoHideDelay: 5000,
        //   variant: "danger",
        // });
      }
    },
  },
  async mounted() {
    this.getData();
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  overflow-x: auto;
  border-radius: 5px 5px 0 0;
  margin-top: 20px;
  position: relative;
  height: 50vh;
  background: #6c757d7c;
  width: 100%;
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  // grid-template-columns: 120px 160px 260px repeat(3, 1fr);
  grid-template-columns: 350px 180px 230px 230px repeat(1, 1fr);
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    /* word-break: keep-all; */
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
    display: -webkit-box;
    height: 36px;
  }
  > div:first-child {
    border-left: 0px;
  }
  > div:last-child {
    border-right: 0px;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
  > div:first-child {
    // border-left: 1px solid #424242;
  }
  > div:last-child {
    // border-right: 1px solid #424242;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #424242;
    color: white;
  }
}
.dtc-link {
  color: var(--primary);
  cursor: pointer;
}
.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 40px;
  margin-bottom: 12px;
  grid-template-columns: 343px repeat(3, 277.5px);
  grid-gap: 12px;
  > * {
    max-height: 44px;
  }
}

.dtc-search1 {
  grid-template-columns: 343px max-content 340px repeat(30, max-content);
}
.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 110px;
  background: #0379fd;
  color: #fff;
}
.big-title {
  border-radius: 5px 5px 0 0;
  padding: 10px;
  margin-bottom: 0;
  background: #697179;
  color: #e9e9e9;
  font-size: 22px;
  font-weight: 500;
  font-family: Microsoft JhengHei, Helvetica;
  border: 1px solid #5b636a;
  .icon {
    color: #e9e9e9;
  }
}
</style>
