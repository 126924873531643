import Vue from "vue";
import VueRouter from "vue-router";
import { store, actions } from "@/store/global.js";
import Home from "../views/Home.vue";
import TotalSpend from "@/components/TotalSpend.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Auth.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/page",
    name: "page",
    component: () => import("../views/LoadPage.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../components/land/Home.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/surveysourcemap",
    name: "surveysourcemap",
    component: () => import("../components/dispatch/SurveySourceMap.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/surveypage",
    name: "surveypage",
    component: () => import("../components/dispatch/SurveyFinalPage.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/surveypersoninfo",
    name: "surveypersoninfo",
    component: () => import("../components/dispatch/SurveyPersonInfo.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/ask901",
    name: "ask901",
    component: () => import("../components/dispatch/Ask901.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/ask902",
    name: "ask902",
    component: () => import("../components/dispatch/Ask902.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/askinservice",
    name: "askinservice",
    component: () => import("../components/dispatch/AskInService.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/ask1001",
    name: "ask1001",
    component: () => import("../components/dispatch/Ask1001.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/askresign",
    name: "askresign",
    component: () => import("../components/dispatch/AskResign.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/surveytable1",
    name: "surveytable1",
    component: () => import("../components/dispatch/SurveyTable1.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/surveytable2",
    name: "surveytable2",
    component: () => import("../components/dispatch/SurveyTable2.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/surveytable3",
    name: "surveytable3",
    component: () => import("../components/dispatch/SurveyTable3.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/surveytable4",
    name: "surveytable4",
    component: () => import("../components/dispatch/SurveyTable4.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/surveytable5",
    name: "surveytable5",
    component: () => import("../components/dispatch/SurveyTable5.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/surveytable6",
    name: "surveytable6",
    component: () => import("../components/dispatch/SurveyTable6.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/surveytablesall",
    name: "surveytablesall",
    component: () => import("../components/dispatch/SurveyTablesAll.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/surveytables",
    name: "surveytables",
    component: () => import("../components/dispatch/SurveyTables.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/surveytablespage",
    name: "surveytablespage",
    component: () => import("../components/dispatch/SurveyTablesPage.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/surveylist",
    name: "surveylist",
    component: () => import("../components/dispatch/SurveyList.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/calendar",
    name: "calendar",
    component: () => import("../components/Calendar.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/totalSpend",
    name: "TotalSpend",
    component: () => import("../components/TotalSpend.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/doc1",
    name: "doc1",
    component: () => import("../components/Doc1.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc1-1",
    name: "doc1-1",
    component: () => import("../components/Doc1-1.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc1-2",
    name: "doc1-2",
    component: () => import("../components/Doc1-2.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc2",
    name: "doc2",
    component: () => import("../components/Doc2.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc3",
    name: "doc3",
    component: () => import("../components/Doc3.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc4",
    name: "doc4",
    component: () => import("../components/Doc4.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc4-1",
    name: "doc4-1",
    component: () => import("../components/Doc4-1.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc5",
    name: "doc5",
    component: () => import("../components/Doc5.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc6",
    name: "doc6",
    component: () => import("../components/Doc6.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc8-1",
    name: "doc8-1",
    component: () => import("../components/Doc8-1.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc8-1-1",
    name: "doc8-1-1",
    component: () => import("../components/Doc8-1-1.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc8-2",
    name: "doc8-2",
    component: () => import("../components/Doc8-2.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc8-3",
    name: "doc8-3",
    component: () => import("../components/Doc8-3.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc8-3-1",
    name: "doc8-3-1",
    component: () => import("../components/Doc8-3-1.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc8-4",
    name: "doc8-4",
    component: () => import("../components/Doc8-4.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc8-5",
    name: "doc8-5",
    component: () => import("../components/Doc8-5.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc8-5-1",
    name: "doc8-5-1",
    component: () => import("../components/Doc8-5-1.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc8-6",
    name: "doc8-6",
    component: () => import("../components/Doc8-6.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc9",
    name: "doc9",
    component: () => import("../components/Doc9.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc10",
    name: "doc10",
    component: () => import("../components/Doc10.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc10-1",
    name: "doc10-1",
    component: () => import("../components/Doc10-1.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/plan",
    name: "plan",
    component: () => import("../components/Plan.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/doc11",
    name: "doc11",
    component: () => import("../components/Doc11.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc101",
    name: "doc101",
    component: () => import("../components/Doc101.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc11-1",
    name: "doc11-1",
    component: () => import("../components/Doc11-1.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc13",
    name: "doc13",
    component: () => import("../components/Doc13.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc14",
    name: "doc14",
    component: () => import("../components/Doc14.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc15",
    name: "doc15",
    component: () => import("../components/Doc15.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc17",
    name: "doc17",
    component: () => import("../components/Doc17.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc18",
    name: "doc18",
    component: () => import("../components/Doc18.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc20",
    name: "doc20",
    component: () => import("../components/Doc20.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc21",
    name: "doc21",
    component: () => import("../components/Doc21.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc22",
    name: "doc22",
    component: () => import("../components/Doc22.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc23",
    name: "doc23",
    component: () => import("../components/Doc23.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc24",
    name: "doc24",
    component: () => import("../components/Doc24.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc24-1",
    name: "doc24-1",
    component: () => import("../components/Doc24-1.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/docsummer",
    name: "docsummer",
    component: () => import("../components/DocSummer.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/apply1",
    name: "apply1",
    component: () => import("../components/dispatch/Apply1.vue"),
    meta: {
      isShowHead: true,
    },
  },
  ,
  {
    path: "/apply2",
    name: "apply2",
    component: () => import("../components/dispatch/Apply2.vue"),
    meta: {
      isShowHead: true,
    },
  },
  ,
  {
    path: "/apply3",
    name: "apply3",
    component: () => import("../components/dispatch/Apply3.vue"),
    meta: {
      isShowHead: true,
    },
  },
  ,
  {
    path: "/apply4",
    name: "apply4",
    component: () => import("../components/dispatch/Apply4.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/apply5",
    name: "apply5",
    component: () => import("../components/enroll/Apply5.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/apply6",
    name: "apply6",
    component: () => import("../components/enroll/Apply6.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/apply27",
    name: "apply27",
    component: () => import("../components/enroll/Apply27.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/apply12",
    name: "apply12",
    component: () => import("../components/enroll/Apply12.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/studentchart",
    name: "ChartDisplay",
    component: () => import("../components/Chart/ChartDisplay.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/studentstatistical",
    name: "studentstatistical",
    component: () => import("../components/statisticalindicators/StudentStatistical.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/dtcvuetabs101",
    name: "dtcvuetab101",
    component: () => import("../components/dispatch/DtcVueTabs101.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/dtcvuetabs102",
    name: "dtcvuetab102",
    component: () => import("../components/dispatch/DtcVueTabs102.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/dtcvuetabs103",
    name: "dtcvuetab103",
    component: () => import("../components/dispatch/DtcVueTabs103.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/dtcvuetabs104",
    name: "dtcvuetab104",
    component: () => import("../components/dispatch/DtcVueTabs104.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/dtcvuetabs105",
    name: "dtcvuetabs105",
    component: () => import("../components/dispatch/DtcVueTabs105.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/dtcvuetabs106",
    name: "dtcvuetabs106",
    component: () => import("../components/dispatch/DtcVueTabs106.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/downloadarea",
    name: "downloadarea",
    component: () => import("@/components/DownloadArea.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/downloadareamanageinterface",
    name: "downloadareamanageinterface",
    component: () => import("@/components/DownloadAreaManage.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/localstudatamainten",
    name: "localstudatamainten",
    component: () =>
      import("@/components/systemmanage/LocalStuDataMainten.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/localstucheckin",
    name: "localstucheckin",
    component: () => import("@/components/systemmanage/LocalStuCheckIn.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/nPMasterstudatamainten",
    name: "nPMasterstudatamainten",
    component: () =>
      import("@/components/systemmanage/NPMasterStuDataMainten.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/ruralnursingdatamainten",
    name: "ruralnursingdatamainten",
    component: () =>
      import("@/components/systemmanage/RuralNursingDataMainten.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/ruralnursingdistribution",
    name: "ruralnursingdistribution",
    component: () =>
      import("@/components/systemmanage/RuralNursingDistribution.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/contractview",
    name: "contractview",
    component: () => import("@/components/systemmanage/ContractView.vue"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/systemmanagetab1",
    name: "systemmanagetab1",
    component: () => import("@/components/systemmanage/SystemManageTab1"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/systemmanagetab2",
    name: "systemmanagetab2",
    component: () => import("@/components/systemmanage/SystemManageTab2"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/systemmanagetab3",
    name: "systemmanagetab3",
    component: () => import("@/components/systemmanage/SystemManageTab3"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/systemmanagetab4",
    name: "systemmanagetab4",
    component: () => import("@/components/systemmanage/SystemManageTab4"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/systemmanagetab5",
    name: "systemmanagetab5",
    component: () => import("@/components/systemmanage/SystemManageTab5"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/myareaschoolpriority",
    name: "myareaschoolpriority",
    component: () => import("@/components/myrarea/SchoolPriorityList"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/myareaspersonaldata",
    name: "myareaspersonaldata",
    component: () => import("@/components/myrarea/PersonalData"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/servicemanagelocalprogressmanage",
    name: "servicemanagelocalprogressmanage",
    component: () => import("@/components/servicemanage/LocalProgressmanage"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/servicemanageruralprogressmanage",
    name: "servicemanageruralprogressmanage",
    component: () => import("@/components/servicemanage/RuralProgressmanage"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/servicemanagetab1",
    name: "servicemanagetab1",
    component: () => import("@/components/servicemanage/ServiceManageTab1"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/hospitalmanagetab1",
    name: "hospitalmanagetab1",
    component: () => import("@/components/hospitalmanage/HospitalManageTab1"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/localbudgetmanagetab",
    name: "localbudgetmanagetab",
    component: () => import("@/components/budgetapply/LocalBudgetManageTab"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/localsubsidyapplicationedit",
    name: "localsubsidyapplicationedit",
    component: () =>
      import("@/components/budgetapply/TabOneSubsidyApplicationEdit"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/localsignlesubapplicationedit",
    name: "localsignlesubapplicationedit",
    component: () =>
      import("@/components/budgetapply/TabOneSingleSubApplicationEdit"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/localsubsidyapplicationeditsemestertwo",
    name: "localsubsidyapplicationeditsemestertwo",
    component: () =>
      import(
        "@/components/budgetapply/TabOneSubsidyApplicationEditSemesterTwo"
      ),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/localwriteoffedit",
    name: "localwriteoffedit",
    component: () =>
      import("@/components/budgetapply/TabTwoSubsidyApplicationEdit"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/localwriteoffeditsemestertwo",
    name: "localwriteoffeditsemestertwo",
    component: () =>
      import(
        "@/components/budgetapply/TabTwoSubsidyApplicationEditSemesterTwo"
      ),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/localsummerapplication",
    name: "localsummerapplication",
    component: () =>
      import("@/components/budgetapply/TabSummerSubsidyApplicationEdit"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/focussummerapplication",
    name: "focussummerapplication",
    component: () =>
      import("@/components/budgetapplyfocus/TabSummerSubsidyApplicationEdit"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/localincomestatement",
    name: "localincomestatement",
    component: () => import("@/components/budgetapply/IncomeStatement"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/printPage",
    name: "printPage",
    component: () => import("@/views/PrintPage"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/ruralbudgetmanagetab",
    name: "ruralbudgetmanagetab",
    component: () =>
      import("@/components/budgetapplyrural/RuralBudgetManageTab"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/ruralsubsidyapplicationedit",
    name: "ruralsubsidyapplicationedit",
    component: () =>
      import("@/components/budgetapplyrural/TabOneSubsidyApplicationEdit"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/ruralsubsidyapplicationeditsemestertwo",
    name: "ruralsubsidyapplicationeditsemestertwo",
    component: () =>
      import(
        "@/components/budgetapplyrural/TabOneSubsidyApplicationEditSemesterTwo"
      ),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/ruralwriteoffedit",
    name: "ruralwriteoffedit",
    component: () =>
      import("@/components/budgetapplyrural/TabThreeSubsidyApplicationEdit"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/ruralwriteoffeditsemestertwo",
    name: "ruralwriteoffeditsemestertwo",
    component: () =>
      import(
        "@/components/budgetapplyrural/TabThreeSubsidyApplicationEditSemesterTwo"
      ),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/ruralincomestatement",
    name: "ruralincomestatement",
    component: () => import("@/components/budgetapplyrural/IncomeStatement"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/focusapplicationedit",
    name: "focusapplicationedit",
    component: () =>
      import("@/components/budgetapplyfocus/TabOneSubsidyApplicationEdit"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/focusapplicationeditsemestertwo",
    name: "focusapplicationeditsemestertwo",
    component: () =>
      import(
        "@/components/budgetapplyfocus/TabOneSubsidyApplicationEditSemesterTwo"
      ),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/focusbudgetmanagetab",
    name: "focusbudgetmanagetab",
    component: () => import("@/components/budgetapplyfocus/FocusBudgetManageTab"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/focuswriteoffedit",
    name: "focuswriteoffedit",
    component: () =>
      import("@/components/budgetapplyfocus/TabTwoSubsidyApplicationEdit"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/focuswriteoffeditsemestertwo",
    name: "focuswriteoffeditsemestertwo",
    component: () =>
      import(
        "@/components/budgetapplyfocus/TabTwoSubsidyApplicationEditSemesterTwo"
      ),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/focusincomestatement",
    name: "focusincomestatement",
    component: () => import("@/components/budgetapplyfocus/IncomeStatement"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/settingmanagetab",
    name: "settingmanagetab",
    component: () => import("@/components/allSetting/AllSettingManageTab"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/authoritymanage",
    name: "authoritymanage",
    component: () => import("@/components/authoritymanage/AuthorityManageTab"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/hcquestionnairemanage",
    name: "hcquestionnairemanage",
    component: () =>
      import(
        "@/components/healthCenterQuestionnaireManage/HcQuestionnaireManageTab"
      ),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/hcquestionnairecentermanage",
    name: "hcquestionnairecentermanage",
    component: () =>
      import(
        "@/components/healthCenterQuestionnaireManage/HcQuestionnaireCenterManage"
      ),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/supportreporting",
    name: "supportreporting",
    component: () =>
      import(
        "@/components/healthCenterQuestionnaireManage/Supportreporting"
      ),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/electronicreviewManageTab",
    name: "electronicreviewManageTab",
    component: () => import("@/components/electronicreview/ElectronicreviewManageTab"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/electronicformManageTab",
    name: "electronicformManageTab",
    component: () => import("@/components/electronic/ElectronicformManageTab"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/localcoursemanage",
    name: "localcoursemanage",
    component: () => import("@/components/course/LocalCourseManageTab"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/nursecoursemanage",
    name: "nursecoursemanage",
    component: () => import("@/components/course/NurseCourseManageTab"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/courseMessageboard",
    name: "courseMessageboard",
    component: () => import("@/components/course/CourseMessageBoard"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/nurserecordmanage",
    name: "nurserecordmanage",
    component: () => import("@/components/nursecouch/NurseCouchManageTab"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/localrecordmanage",
    name: "localrecordmanage",
    component: () => import("@/components/localcouch/LocalCouchManageTab"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/handandquestionmanage",
    name: "handandquestionmanage",
    component: () =>
      import("@/components/handAndQuestion/HandAndQuestionManageTab"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/alldownloadfile",
    name: "alldownloadfile",
    component: () => import("@/components/DownloadAreaManageTab"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/bulletinmaintain",
    name: "bulletinmaintain",
    component: () => import("@/components/BulletinMaintain"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/registercondition",
    name: "registercondition",
    component: () => import("@/components/RegisterCondition"),
    meta: {
      isShowHead: false,
    },
  },

  {
    path: "/rptQueryLocal",
    name: "rptQueryLocal",
    component: () => import("@/components/rpt/QueryLocal"),
    meta: {
      isShowHead: true,
    },
  },
  {
    path: "/doc24-2-1",
    name: "doc24-2-1",
    component: () => import("../components/Doc24-2-1.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/doc24-2-2",
    name: "doc24-2-2",
    component: () => import("../components/Doc24-2-2.vue"),
    meta: {
      isShowHead: false,
    },
  },
  {
    path: "/tabOneapplicationeditp",
    name: "tabOneapplicationeditp",
    component: () => import("@/components/budgetapply/TabOneApplicationEditP"),
    meta: {
      isShowHead: true,
    },
  },

  {
    path: "/tabOneapplicationeditsemesterp",
    name: "tabOneapplicationeditsemesterp",
    component: () => import("@/components/budgetapply/TabOneApplicationEditSemesterP"),
    meta: {
      isShowHead: true,
    },
  },

];

const router = new VueRouter({
  //mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// 路由守衛;
router.beforeEach((to, from, next) => {
  // console.log(to);
  if (window.sessionStorage.token) {
    store.isLogin = true;
    if (to.path === "/") {
      next({ path: "/home" });
    } else {
      next();
    }
  } else {
    if (to.path === "/login") {
      next();
    } else if (to.path === "/registercondition") {
      next();
    } else {
      next({ path: "/login" });
    }
  }
});

export default router;
