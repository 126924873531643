import Vue from "vue";
import Vuex from "vuex";

// export let store = Vue.observable(
//   { editItem: null },
//   { editPerson: null },
//   { userRight: null },
//   { downloadEditItem: null },
//   { allTableItem: null },
//   { nowIndex: null },
//   { studentProgressInfo: null },
//   { isLoading: false}
// );

export let store = Vue.observable({
  isIdle: false,
  isLogin: false,

  totalAllInfoCount: 0,
  isAlreadyCheckInfo: false,
  filekind: 1,
  printItem: null,
  editRecord: {},
  editItem: null,
  editPerson: null,
  userRight: null,
  downloadEditItem: null,
  allTableItem: null,
  nowIndex: null,
  studentProgressInfo: null,
  isLoading: false,
  schoolList: [],
  schoolCode: null,
  schoolName: null,
  optionList: {
    school: [],
    semester: [
      { text: "第一學期(上學期)", value: 1 },
      { text: "第二學期(下學期)", value: 2 },
    ],
  },
  nowTab: null,
  nowTableTitle: null,
  semester: 1,
  uppPriceArr: [],
  accountName: "",
  studentInfo: [],
});

Vue.use(Vuex);

export let actions = {
  logout() {
    store.totalAllInfoCount = 0;
    store.isAlreadyCheckInfo = false;
    store.filekind = 1;
    store.printItem = null;
    store.editRecord = {};
    store.isLogin = false;
    store.editItem = null;
    store.editPerson = null;
    store.userRight = null;
    store.downloadEditItem = null;
    store.allTableItem = null;
    store.nowIndex = null;
    store.studentProgressInfo = null;
    store.isLoading = false;
    store.schoolList = [];
    store.schoolCode = null;
    store.schoolName = null;
    store.optionList = {
      school: [],
      semester: [
        { text: "第一學期(上學期)", value: 1 },
        { text: "第二學期(下學期)", value: 2 },
      ],
    };
    store.nowTab = null;
    store.nowTableTitle = null;
    store.semester = 1;
    store.accountName = "";
    store.uppPriceArr = [];
    store.studentInfo = [];
    sessionStorage.clear();
    localStorage.clear();
  },
};

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {},
});
