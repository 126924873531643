<template>
  <section class="dtc-main-section">
    <h4 class="big-title">
      <span class="icon"><i class="fas fa-graduation-cap"></i></span>
      履約終止
    </h4>
    <header class="dtc-grid-header my-dark">
      <div v-for="(item, i) in headers" :key="`headers${i}`" :title="item">
        {{ item.name || item }}
      </div>
    </header>
    
    <!--尚無對應功能，顯示暫無資料-->
    <main
      class="mt-2"
      style="grid-column: 1/-1;font-size:26px;text-align:center;font-weight:600"
    >
      暫無資料
    </main>

    <!--暫無資料，保留原程式-->
    <!--<main
      v-if="!items.length"
      class="mt-2"
      style="grid-column: 1/-1;font-size:26px;text-align:center;font-weight:600"
    >
      暫無資料
    </main>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#EDEDED ;' : 'background-color: #DDDDDD;'
      "
    >
      <div>{{ i + 1 }}</div>
      <div>{{ item.ServiceItem || "" }}</div>
      <div>{{ item.StartDate || "" }}</div>
      <div>{{ item.ExpectedDate || "" }}</div>
      <div>{{ item.Remark || "" }}</div>
    </main>-->
  </section>
</template>

<script>
import queryString from "query-string";
import buildQuery from "odata-query";
import { store } from "@/store/global.js";
import moment from "moment";

const headers = ["服務現況", "終止日期", "備註"];

// const headers = [
//   { name: "序", key: "", sortDesc: null },
//   { name: "服務現況", key: "", sortDesc: null },
//   { name: "起始日期", key: "CountryName", sortDesc: null },
//   { name: "逾時日期", key: "CountryId", sortDesc: null },
//   { name: "追辦管理原因", key: "Cid", sortDesc: null },
// ];

const zero = "T00:00:00";
const rows = [10, 20, 50];
const year = new Date().getFullYear() - 1911;
let years = new Array(9).fill().map((s, i) => year + 1 - i);
years.unshift({ value: null, text: "全部" });

export default {
  name: "downarea",
  data() {
    return {
      headers,
      items: [],
      keys: [],
    };
  },
  components: {},
  computed: {},

  methods: {
    async getData() {
      const id = store.studentProgressInfo.id;

      const url = `api/studentServiceInfo?$filter=Identifier eq '${id}'`;

      try {
        let { Items } = await window.axios.get(url);
        Items.sort(function(a, b) {
          return a.StartDate - b.StartDate;
        });
        this.items = JSON.parse(JSON.stringify(Items));
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    this.getData();
  },

  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  overflow-x: auto;
  border-radius: 5px 5px 0 0;
  margin-top: 20px;
  position: relative;
  height: 50vh;
  background: #1f833783;
  width: 100%;
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 150px 350px repeat(1, 1fr);
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    /* word-break: keep-all; */
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
    display: -webkit-box;
    height: 36px;
  }
  > div:first-child {
    border-left: 0px;
  }
  > div:last-child {
    border-right: 0px;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
  > div:first-child {
    // border-left: 1px solid #424242;
  }
  > div:last-child {
    // border-right: 1px solid #424242;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #424242;
    color: white;
  }
}
.dtc-link {
  color: var(--primary);
  cursor: pointer;
}
.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 40px;
  margin-bottom: 12px;
  grid-template-columns: 343px repeat(3, 277.5px);
  grid-gap: 12px;
  > * {
    max-height: 44px;
  }
}

.dtc-search1 {
  grid-template-columns: 343px max-content 340px repeat(30, max-content);
}
.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 110px;
  background: #0379fd;
  color: #fff;
}
.big-title {
  border-radius: 5px 5px 0 0;
  padding: 10px;
  margin-bottom: 0;
  background: #27a243;
  color: #e9e9e9;
  font-size: 22px;
  font-weight: 500;
  font-family: Microsoft JhengHei, Helvetica;
  border: 1px solid #23923c;
  .icon {
    color: #e9e9e9;
  }
}
</style>
